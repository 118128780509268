import {
	Component,
	OnInit,
	HostListener,
	ViewChild,
	ElementRef,
	Renderer2
} from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationStart } from "@angular/router";
import { Globals } from "./services/Globals";
import { AuthService } from "./services/auth.service";
import { Ng4LoadingSpinnerService } from "../../node_modules/ng4-loading-spinner";
import { ApiService } from "./services/api.service";
declare var moment: any;
declare var require: any;

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html"
})
export class AppComponent {
	title = "Psycho Counselor";
	template: string = '<img src="assets/imgs/oval.svg" />'
	hide = false

	constructor(public translate: TranslateService,
		private router: Router,
		private globals: Globals,
		public authService: AuthService,
		public apiService: ApiService,
		private spinnerService: Ng4LoadingSpinnerService) {

		// apiService.getTimezone().subscribe((data: any) => {
		// 	apiService.timezone = data.timezone
		// })

		let lang = globals.secureStorage.getItem('current:lang') ? globals.secureStorage.getItem('current:lang') : 'en'
		translate.setDefaultLang(lang)
		translate.setTranslation(lang, require(`assets/i18n/${lang}.json`))
		translate.use(lang)
		// moment.locale(lang)

		if (lang == 'ar')
			document.body.classList.add('rtl', 'ar-font')
		else document.body.classList.remove('rtl', 'ar-font')

		router.events.subscribe((event: NavigationStart) => {
			if (event instanceof NavigationStart) {
				this.globals.hideSpinner()

				this.hide = event.url.indexOf('/stream/') > -1 || event.url.indexOf('after_payment') > -1

				setTimeout(() => {
					let btns = document.querySelectorAll('.btn, .dropdown-button')
					for (let i = 0; i < btns.length; i++) {
						btns[i].removeEventListener('mousedown', null)
						btns[i].addEventListener('mousedown', (e: MouseEvent) => {
							if (e.which == 1) {
								let x = Math.abs(Math.round(e.pageX - (<HTMLElement>btns[i]).getBoundingClientRect().left))
								let y = Math.abs(Math.round(e.pageY - (<HTMLElement>btns[i]).getBoundingClientRect().top))

								let ripple = document.createElement('SPAN')
								ripple.classList.add('ripple')
								ripple.style.left = `${x}px`
								ripple.style.top = `${y}px`

								let oldRipple = btns[i].querySelector('.ripple')
								if (oldRipple)
									btns[i].removeChild(oldRipple)
								btns[i].appendChild(ripple)
							}
						})
					}
				}, 1000)
			}

			if (navigator.userAgent.indexOf('Edge') < 0)
				document.body.scrollTo(0, 0)
			window.scrollTo(0, 0)
		})

		if (globals.currentUser) {
			authService.me().subscribe((res: any) => {
				if (res.message == null) {
					globals.currency = res.data.currency
					globals.currentUser.user = res.data
					console.log('globals.currentUser.user',globals.currentUser.user);
					
					globals.secureStorage.setItem("currentUser", JSON.stringify(globals.currentUser))
				}
				else {
					authService.logout()
					authService.updateCredentials()
				}
			},
				() => {
					authService.logout()
					authService.updateCredentials()
				})
		}
	}
}
