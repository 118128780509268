import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "base64code"
})
export class Base64codePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let a = "";

    if (value) {
      a = atob(value);
    }

    return a;
  }
}
