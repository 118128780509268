import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class CmsService {

  constructor(private api: ApiService) { }

  contactUs = details => this.api.anyFetch(this.api.urls.contact_us, details)

  getCities = id => this.api.anyFetch(`${this.api.urls.getCities}/${id}`, {}, "get")

  getLandingInfo = () => this.api.anyFetch(this.api.urls.landingData, {}, "get")

  allArticles = () => this.api.anyFetch(this.api.urls.allArticles, {}, "get")
  allArticlesMore = (page) => this.api.anyFetch(this.api.urls.allArticles, { page }, "get")

  article = (id) => this.api.anyFetch(`${this.api.urls.article}/${id}`, {}, "get")
}
