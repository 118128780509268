import { Component, OnInit, ViewChild } from '@angular/core';
import { Globals } from '../../services/Globals';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { UserService } from '../../services/user.service';
import { ApiService } from '../../services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { PlatformLocation } from '@angular/common'
import { Events } from 'ionic-angular';
declare var RTCMultiConnection:any;
declare var moment: any;
declare var screenfull: any;

@Component({
	selector: 'app-stream',
	templateUrl: './stream.component.html',
	styleUrls: ['./stream.component.scss']
})
export class StreamComponent implements OnInit {
	@ViewChild("endSessionModal") endSessionModal
	@ViewChild("confirmModal") confirmModal

	token
	access_token = null
	connection
	socket

	checked = false
	isStarted = false
	isAudioMuted = false
	isVideoMuted = false

	localVideo
	localVideoEvent
	remoteVideo

	displayInfo = true
	displayLoading = true

	seconds = 0
	time = "00:00:00"
	timer = null
	sessionData = null

	otherData = null

	title = ''
	comment = ''
	rating = 0
	notes = ''

  iframeurl = 'https://meet.gcore.com/call/psyco'

	constructor(public globals: Globals,
				private route: ActivatedRoute,
				private userService: UserService,
				private router: Router,
				public apiService: ApiService,
				private modalService: NgbModal,
				private spinnerService: Ng4LoadingSpinnerService,
				private titleService: Title,
				private translate: TranslateService,
				private events: Events) {
		translate.get('titles.stream').subscribe(value => titleService.setTitle(value))
		this.token = this.route.snapshot.params.id
		if (this.route.snapshot.queryParams.access_token !== undefined && this.route.snapshot.queryParams.access_token != null) {
			this.access_token = this.route.snapshot.queryParams.access_token
      this.iframeurl = 'https://meet.gcore.com/call/' + this.access_token
			this.displayInfo = false
		}

		router.events.subscribe((event: NavigationEnd) => {
			if (event instanceof NavigationEnd && event.url.indexOf('/stream/') < 0) {
				this.socket.close()
				this.connection.attachStreams.forEach((stream) => {
					stream.stop()
				})
				this.connection.close()
			}
		})
	}

	ngOnInit() {
		(<any>window).iOSDefaultAudioOutputDevice = 'speaker'
		this.connection = new RTCMultiConnection()
		this.connection.enableLogs = true
		this.connection.socketURL = 'https://stream.psycounslers.com:9001/';
		this.connection.socketOptions = {
			'force new connection': true,
			'forceNew': true,
			'transport': 'polling'
		}

		this.connection.session = {
			audio: true,
			video: true
		}

		this.connection.sdpConstraints.mandatory = {
			OfferToReceiveAudio: true,
			OfferToReceiveVideo: true
		}

		this.connection.onstream = (event) => {
			if (event.type != 'local') {
				if (!this.checked && !this.access_token && this.globals.currentUser.user.role.name != 'doctor' && this.connection.peers.getLength() > 1) {
					this.router.navigate(['/user/sessions'])
					this.globals.showToast(this.translate.instant('sessionSameTime'), 'error')
				}

				this.checked = true
			}

			if (event.userid != "admin") {
				let video = event.mediaElement
				video.removeAttribute("controls")
				video.setAttribute('playsinline', 'true')
				video.setAttribute('autoplay', 'true')
				video.play()

				if (event.type == 'local') {
					document.querySelector('.local_video').appendChild(video)
					video.play()
					this.localVideo = document.querySelector('.local_video .video')
					this.localVideoEvent = event
				}
				else {
					if (!document.querySelector('.remote_video video'))
						document.querySelector('.remote_video').appendChild(video)
					this.remoteVideo = document.querySelector('.remote_video .video')
					this.displayLoading = false
					video.play();
					setTimeout(() => {
						(<HTMLVideoElement>document.querySelector('.remote_video video')).play()
					}, 500)
				}
			}
		}

		this.connection.connectSocket()
		this.socket = this.connection.socket

		if (!this.access_token && this.globals.currentUser.user.role.name == 'doctor') {
			this.connection.userid = "doctor"
			this.connection.extra = { userid: "doctor" }
		}

		this.socket.on('custom_message', (message) => {
			if(message.token == this.token) {
				if (message.type == 'endSession') {
					this.socket.close()
					this.modalService.open(this.endSessionModal)
					this.globals.secureStorage.removeItem('currentSessionKey')
					if (!this.access_token && this.globals.currentUser.user.role.name == 'doctor')
						this.router.navigate(['/doctor/sessions'])
					else this.router.navigate(['/user/sessions'])
				}
				else if (!this.access_token && message.type == 'doctor_joined' && this.globals.currentUser.user.role.name == 'user')
					location.reload()
				else if (message.type == 'endSession-client') {
					this.globals.secureStorage.removeItem('currentSessionKey')
					this.modalService.open(this.endSessionModal)
				}
			}
		})

		this.connection.onmute = (e) => {
			if (e.type == 'local') {
				if (e.muteType == 'audio')
					this.isAudioMuted = true
				if (e.muteType == 'video')
					this.isVideoMuted = true
			}
		}

		this.connection.onunmute = (e) => {
			if (e.type == 'local') {
				if (e.unmuteType == 'audio')
					this.isAudioMuted = false
				if (e.unmuteType == 'video')
					this.isVideoMuted = false
			}
		}

		let key = this.globals.secureStorage.getItem('currentSessionKey')
		if (key == null) {
			this.globals.secureStorage.setItem('currentSessionKey', Math.random().toString(36).replace(/[^a-z]+/g, ''))
			key = this.globals.secureStorage.getItem('currentSessionKey')
		}

		this.userService.checkSession(this.token, key, this.access_token).subscribe((res: any) => {
			if (res.type == 'error') {
				if (!this.access_token && this.globals.currentUser.user.role.name == 'doctor')
					this.router.navigate(['/doctor/sessions'])
				else this.router.navigate(['/user/sessions'])
				return false
			}
			else if(!this.access_token && this.globals.currentUser.user.role.name == 'user') {
				if(res.data.is_started == 0) {
					if (!this.access_token && this.globals.currentUser.user.role.name == 'doctor')
						this.router.navigate(['/doctor/sessions'], { queryParams: { status: 'session_not_started' } })
					else this.router.navigate(['/user/sessions'], { queryParams: { status: 'session_not_started' } })
					return false
				}

				if(res.data.is_started == 1 && res.data.end_date != null) {
					if (!this.access_token && this.globals.currentUser.user.role.name == 'doctor')
						this.router.navigate(['/doctor/sessions'], { queryParams: { status: 'session_ended' } })
					else this.router.navigate(['/user/sessions'], { queryParams: { status: 'session_ended' } })
					return false
				}
			}

			if (this.apiService.role == 'doctor')
				this.otherData = res.data.user
			else this.otherData = res.data.doctor_details.doctor

			this.sessionData = res.data
			if (res.data.is_started == 1) {
				this.isStarted = true
				this.joinSession()

				let now = moment(new Date())
				let end = moment(this.sessionData.start_date)
				this.seconds = moment.duration(now.diff(end)).asSeconds()

				this.startCounter()
			}
		})
	}

	openChat = () => {
		let id = this.otherData.id
		this.events.publish('chat:open', id)
	}

	startCounter = () => {
		if (this.timer)
			clearInterval(this.timer)
		this.timer = setInterval(() => {
			this.seconds++
			this.time = this.secondsToTime(this.seconds)
		}, 1000)
	}

	secondsToTime = (secs) => moment("1900-01-01 00:00:00").add(secs, 'seconds').format("HH:mm:ss")

	joinSession = () => {
		if(!this.access_token && this.globals.currentUser.user.role.name == 'doctor') {
			if (!this.isStarted) {
				this.userService.startSession(this.token, this.access_token).subscribe((res: any) => {
					if(res.type == 'success') {
						this.connection.open(this.token)
						this.isStarted = true
						this.socket.emit('custom_message', { type: 'doctor_joined', token: this.token })
						this.startCounter()

						this.globals.socket.emit('session', { userId: this.otherData.id, doctorName: this.globals.currentUser.user.name })
					}
				})
			}
			else {
				this.connection.open(this.token)
				this.connection.send('doctor_joined')
				this.socket.emit('custom_message', { type: 'doctor_joined', token: this.token })
				this.startCounter()
			}
		}
		else this.connection.join(this.token)
	}

	endSessionCheck = () => {
		this.modalService.open(this.confirmModal)
	}

	endSession = () => {
		this.modalService.open(this.endSessionModal)

		if (this.apiService.role == 'doctor')
			this.socket.emit('custom_message', { token: this.token, type: 'endSession' })
		else  {
			this.socket.emit('custom_message', { token: this.token, type: 'endSession-client' })
			this.socket.close()
			if (!this.access_token && this.globals.currentUser.user.role.name == 'doctor')
				this.router.navigate(['/doctor/sessions'])
			else {
				if (!this.access_token)
					this.router.navigate(['/user/sessions'])
				else this.router.navigate(['/home'])
			}
		}
	}

	submitNotes = () => {
		this.globals.showSpinner()
		this.userService.endSession(this.token, this.notes, this.access_token).subscribe((res: any) => {
			this.connection.close()
			if (!this.access_token && this.globals.currentUser.user.role.name == 'doctor')
				this.router.navigate(['/doctor/sessions'])
			else this.router.navigate(['/user/sessions'])
			this.globals.hideSpinner()
		},
		() => this.globals.hideSpinner())
	}

	submitReview = () => {
		this.globals.showSpinner()
		this.userService.reviewSession(this.sessionData.doctor_details.doctor_id, this.sessionData.id, this.rating, this.title, this.comment, this.access_token).subscribe((res: any) => {
			this.globals.hideSpinner()
			document.location.reload()
		},
		() => this.globals.hideSpinner())
	}

	changeRating = ($event) =>  {
		this.rating = $event.rating
	}

	mute = (type) => {
		if (type == 'audio') {
			if (this.isAudioMuted)
				this.connection.streamEvents[this.localVideoEvent.streamid].stream.unmute(type)
			else this.connection.streamEvents[this.localVideoEvent.streamid].stream.mute(type)
		}
		else if (type == 'video') {
			if (this.isVideoMuted)
				this.connection.streamEvents[this.localVideoEvent.streamid].stream.unmute(type)
			else this.connection.streamEvents[this.localVideoEvent.streamid].stream.mute(type)
		}
	}

	toggleFullScreen = () => screenfull.enabled ? screenfull.toggle(document.body) : null
}
