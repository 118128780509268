import {
	Component,
	OnInit,
	Renderer2,
	OnDestroy,
	ElementRef,
	ViewChild
} from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { Globals } from "../../services/Globals";
import { AuthService } from "../../services/auth.service";
import { ISubscription } from "rxjs/Subscription";
import { StatesService } from "../../services/states.service";
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { DoctorsService } from "../../services/doctors.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "../../services/user.service";
import { Events } from "ionic-angular";
import { CmsService } from "../../services/cms.service";
import { FilterService } from "../../services/filter.service";
declare var Echo: any;
declare var moment: any;
declare var require: any;
declare var require: any;

@Component({
	selector: "navbar",
	templateUrl: "./navbar.component.html",
	styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit, OnDestroy {
	@ViewChild('threadModal') threadModal
	@ViewChild('howToModal') howtoModal
	@ViewChild('content') editProfileRefContent

	editProfileRef: BsModalRef
	newMessageRef: BsModalRef
	threadModalRef: BsModalRef
	howToModalRef: BsModalRef

	navState = "fixed"
	route: string
	menuOpen = false
	isLoggedIn = false
	subscriptions: Array<ISubscription> = []
	pageScrolled = false
	oldPasswordWrong = false

	profilePicture
	userProfileForm = null
	profilePicture64
	doctorProfile = {
		name: "",
		email: "",
		country_id: -1,
		city_id: -1,
		street1: "",
		building: "",
		floor: "",
		apartment: ""
	}

	password = {
		old: "",
		new: ""
	}

	passwordToggle = true

	hasCamera = false
	hasMic = false
	hasAudio = false

	notifications = []
	loadingNotifications = true
	unreadNotificationsCount = 0

	messages = []
	loadingMessages = true
	unreadMessagesCount = 0
	participants = []
	messageParticipant = 0
	messageBody = ''
	hasNewMessage = false
	showNewMessageNoti = false
	unreadNewMessages = 0

	currentThread = null
	loadingThread = false
	currentThreadMessages = []
	messageToSend = ''
	sendingMessage = false

	intl

	countries = []
	cities = []

	constructor(
		public el: ElementRef,
		public renderer: Renderer2,
		public router: Router,
		public globals: Globals,
		public authService: AuthService,
		public userService: UserService,
		public states: StatesService,
		private modalService: BsModalService,
		public doctorService: DoctorsService,
		public spinnerService: Ng4LoadingSpinnerService,
		public translate: TranslateService,
		private events: Events,
		private cms: CmsService,
		private filterService: FilterService
	) {
		if (typeof Intl == 'undefined' || !Intl)
			this.intl = require('intl')

		router.events.forEach(event => {
			if (event instanceof NavigationStart) {
				if (!this.states.transparentWith.includes(event.url)) {
					this.renderer.addClass(this.el.nativeElement, "static");
				}
			}
		});
	}

	ngOnInit() {
		// this.detectRTC()

		this.subscriptions.push(
			this.authService.checkAuth().subscribe(loginState => {
				this.isLoggedIn = loginState;
			})
		);

		this.filterService.getFilterData().subscribe((res: any) => {
			this.countries = res.countries
		})

		if (this.globals.currentUser) {
			this.globals.initSocketConnection()

			this.updateDoctorProfile()

			this.userProfileForm = new FormGroup({
				name: new FormControl(this.doctorProfile.name, Validators.required),
				email: new FormControl(
					this.doctorProfile.email,
					Validators.compose([
						Validators.required,
						Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
					])
				),
				country_id: new FormControl(this.doctorProfile.country_id, Validators.required),
				city_id: new FormControl(this.doctorProfile.city_id, Validators.required),
				street1: new FormControl(this.doctorProfile.street1, Validators.required),
				apartment: new FormControl(this.doctorProfile.apartment, Validators.required),
				building: new FormControl(this.doctorProfile.building, Validators.required),
				floor: new FormControl(this.doctorProfile.floor, Validators.required),
			})
		}

		if (this.checkStaticClass()) {
			document.body.addEventListener('scroll', () => this.pageScrolled = document.body.scrollTop > 0)
			window.addEventListener('scroll', () => this.pageScrolled = window.scrollY > 0)
		}

		// this.loadEchoServer()
		this.events.subscribe('loggedIn', () => this.loadEchoServer())
		this.events.subscribe('chat:open', (id) => {
			this.globals.showSpinner()

			let tId = null
			if (this.messages.length == 0) {
				this.getMessages(() => {
					this.messages.forEach(msg => {
						if (msg.users[0].id == id)
							tId = msg.id
					})

					this.globals.hideSpinner()
					this.openThread(this.threadModal, tId)
				})
			}
			else {
				this.messages.forEach(msg => {
					if (msg.users[0].id == id)
						tId = msg.id
				})

				this.globals.hideSpinner()
				this.openThread(this.threadModal, tId)
			}
		})

		this.events.subscribe('show-update-modal', () => {
			this.editProfileRef = this.modalService.show(this.editProfileRefContent, { class: "fast-modal book-now-modal theme-modal", animated: true, backdrop: 'static', keyboard: false })
		})

		this.events.subscribe('msg-receive', (data) => {
			console.log('msg', data);
			if (this.currentThread != null && this.currentThread.users[0].id == data.user_id) {
				let msgsCont = document.querySelector('.messages-container')

				if (msgsCont.scrollTop == (msgsCont.scrollHeight - msgsCont.clientHeight)) {
					this.hasNewMessage = false
					this.showNewMessageNoti = false
					this.unreadNewMessages = 0
				}
				else {
					this.hasNewMessage = true
					this.showNewMessageNoti = true
					this.unreadNewMessages++
				}

				this.currentThreadMessages.push({
					user_id: data.user_id,
					body: data.body,
					created_at: moment(data.created_at).format('YYYY-MM-DD HH:mm:ss')
				})

				new Audio('assets/media/chat_msg_rec.mp3').play()

				if (!this.showNewMessageNoti) {
					setTimeout(() => {
						msgsCont.scrollTo(0, msgsCont.scrollHeight)
					}, 100)
				}

				this.userService.readThread(this.currentThread.id).subscribe((res) => { })

				if (data.unread_messages == 1)
					this.globals.currentUser.user.unread_messages = 0
			}
			else {
				new Audio('assets/media/chat_alert.mp3').play()
				this.globals.showToast(`<div class="clearfix"><img src="assets/imgs/messages_white.png" width="35" class="float-left"> <div class="noti-msg float-left ml-15"><strong>${data.username}</strong><p class="op-70 mb-0">"${this.formatMessageDisplay(data.body)}"</p></div> </div>`, "info")
				this.globals.currentUser.user.unread_messages = data.unread_messages
			}
		})

		this.events.subscribe('reservation-made', () => {
			this.globals.currentUser.user.unread_notifications = this.globals.currentUser.user.unread_notifications + 1
			this.globals.secureStorage.setItem("currentUser", JSON.stringify(this.globals.currentUser))
			this.globals.showToast(this.translate.instant('youHaveNewReservation'), "info", {
				disableTimeOut: true,
				closeButton: true
			})
			new Audio('assets/media/chat_alert.mp3').play()
			this.events.publish('refresh-schedule')
		})

		this.events.subscribe('session-started', (data) => {
			if (this.globals.currantLanguage == 'en') {
				this.globals.showToast(data, "info", {
					disableTimeOut: true,
					closeButton: true
				})
			} else {
				this.globals.showToast('لديك جلسة الان ', "info", {
					disableTimeOut: true,
					closeButton: true
				})
			}

			new Audio('assets/media/chat_alert.mp3').play()
		})
	}

	updateDoctorProfile = () => {
		this.doctorProfile.name = this.globals.currentUser.user.name
		this.doctorProfile.email = this.globals.currentUser.user.email

		this.doctorProfile.country_id = this.globals.currentUser.user.profile ? this.globals.currentUser.user.profile.country_id : -1
		this.doctorProfile.city_id = this.globals.currentUser.user.profile ? this.globals.currentUser.user.profile.city_id : -1
		this.doctorProfile.street1 = this.globals.currentUser.user.profile ? this.globals.currentUser.user.profile.street1 : ''
		this.doctorProfile.apartment = this.globals.currentUser.user.profile ? this.globals.currentUser.user.profile.apartment : ''
		this.doctorProfile.building = this.globals.currentUser.user.profile ? this.globals.currentUser.user.profile.building : ''
		this.doctorProfile.floor = this.globals.currentUser.user.profile ? this.globals.currentUser.user.profile.floor : ''

		if (this.doctorProfile.country_id === null)
			this.doctorProfile.country_id = -1
		if (this.doctorProfile.city_id === null)
			this.doctorProfile.city_id = -1
		if (this.doctorProfile.street1 === null)
			this.doctorProfile.street1 = ""
		if (this.doctorProfile.apartment === null)
			this.doctorProfile.apartment = ""
		if (this.doctorProfile.building === null)
			this.doctorProfile.building = ""
		if (this.doctorProfile.floor === null)
			this.doctorProfile.floor = ""

		if (this.doctorProfile.country_id != -1)
			this.getCities(this.doctorProfile.country_id)

		this.profilePicture = this.globals.currentUser.user.avatar
	}

	loadEchoServer() {
		this.globals.initSocketConnection()
		// if (this.globals.currentUser != null) {
		// 	this.globals.Echo = new Echo({
		// 		broadcaster: 'socket.io',
		// 		host: 'https://psycounselor.com:4618',
		// 		auth: {
		// 			headers: {
		// 				Authorization: `Bearer ${this.globals.currentUser.access_token}`
		// 			}
		// 		}
		// 	})

		// 	this.globals.Echo.private(`App.User.${this.globals.currentUser.access_token}`).notification((notification) => {
		// 		if (notification.type === 'App\\Notifications\\ReservationMade') {
		// 			this.globals.currentUser.user.unread_notifications = this.globals.currentUser.user.unread_notifications + 1
		// 			this.globals.secureStorage.setItem("currentUser", JSON.stringify(this.globals.currentUser))
		// 			this.globals.showToast(this.translate.instant('youHaveNewReservation'), "info", {
		// 				disableTimeOut: true,
		// 				closeButton: true
		// 			})
		// 			new Audio('assets/media/chat_alert.mp3').play()
		// 		}
		// 		else if (notification.type === 'App\\Notifications\\UpcomingSession') {
		// 			this.globals.currentUser.user.unread_notifications = this.globals.currentUser.user.unread_notifications + 1
		// 			this.globals.secureStorage.setItem("currentUser", JSON.stringify(this.globals.currentUser))

		// 			this.globals.showToast(notification.message, "info", {
		// 				disableTimeOut: true,
		// 				closeButton: true
		// 			})
		// 			new Audio('assets/media/chat_alert.mp3').play()
		// 		}
		// 		else if (notification.type === 'App\\Notifications\\SessionStarted') {
		// 			this.globals.showToast(notification.message, "info", {
		// 				disableTimeOut: true,
		// 				closeButton: true
		// 			})
		// 			new Audio('assets/media/chat_alert.mp3').play()
		// 		}
		// 		else if (notification.type === 'App\\Notifications\\MessageSent') {
		// 			if (this.currentThread != null && this.currentThread.users[0].id == notification.user.id) {
		// 				let msgsCont = document.querySelector('.messages-container')

		// 				if (msgsCont.scrollTop == (msgsCont.scrollHeight - msgsCont.clientHeight)) {
		// 					this.hasNewMessage = false
		// 					this.showNewMessageNoti = false
		// 					this.unreadNewMessages = 0
		// 				}
		// 				else {
		// 					this.hasNewMessage = true
		// 					this.showNewMessageNoti = true
		// 					this.unreadNewMessages++
		// 				}

		// 				this.currentThreadMessages.push({
		// 					user_id: notification.user.id,
		// 					body: notification.message,
		// 					created_at: moment().format('YYYY-MM-DD HH:mm:ss')
		// 				})

		// 				new Audio('assets/media/chat_msg_rec.mp3').play()

		// 				if (!this.showNewMessageNoti) {
		// 					setTimeout(() => {
		// 						msgsCont.scrollTo(0, msgsCont.scrollHeight)
		// 					}, 100)
		// 				}

		// 				this.userService.readThread(this.currentThread.id).subscribe((res) => { })

		// 				if (notification.unread_messages == 1)
		// 					this.globals.currentUser.user.unread_messages = 0
		// 			}
		// 			else {
		// 				new Audio('assets/media/chat_alert.mp3').play()
		// 				this.globals.showToast(`<div class="clearfix"><img src="assets/imgs/messages_white.png" width="35" class="float-left"> <div class="noti-msg float-left ml-15"><strong>${notification.user.name}</strong><p class="op-70 mb-0">"${this.formatMessageDisplay(notification.message)}"</p></div> </div>`, "info")
		// 				this.globals.currentUser.user.unread_messages = notification.unread_messages
		// 			}

		// 			this.globals.secureStorage.setItem("currentUser", JSON.stringify(this.globals.currentUser))
		// 		}
		// 	})
		// }
	}
	// loginListner() {
	// 	this.loadEchoServer()
	// }

	// detectRTC = () => {
	// 	this.globals.rtcMultiConnection.DetectRTC.load(() => {
	// 		this.hasAudio = this.globals.rtcMultiConnection.DetectRTC.hasSpeakers
	// 		this.hasCamera = this.globals.rtcMultiConnection.DetectRTC.hasWebcam
	// 		this.hasMic = this.globals.rtcMultiConnection.DetectRTC.hasMicrophone
	// 	})
	// }

	handleContainerScroll = () => {
		if (this.hasNewMessage) {
			let msgsCont = document.querySelector('.messages-container')
			this.showNewMessageNoti = this.hasNewMessage = msgsCont.scrollTop != (msgsCont.scrollHeight - msgsCont.clientHeight)
			if (!this.showNewMessageNoti)
				this.unreadNewMessages = 0
		}
	}

	scrollToMsgsBottom = () => {
		let msgsCont = document.querySelector('.messages-container')
		msgsCont.scrollTo(0, msgsCont.scrollHeight)
	}

	logout() {
		this.authService.logout();
		this.authService.updateCredentials();
		this.router.navigate(["/"]);
		this.isLoggedIn = false;
	}

	ngOnDestroy() {
		this.subscriptions.forEach(sub => sub.unsubscribe())
	}

	open(content) {
		if (this.globals.currentUser) {
			this.globals.showSpinner()
			this.authService.me().subscribe((res: any) => {
				if (res.message == null) {
					this.globals.currency = res.data.currency
					this.globals.currentUser.user = res.data
					this.globals.secureStorage.setItem("currentUser", JSON.stringify(this.globals.currentUser))

					this.updateDoctorProfile()

					this.userProfileForm = new FormGroup({
						name: new FormControl(this.doctorProfile.name, Validators.required),
						email: new FormControl(
							this.doctorProfile.email,
							Validators.compose([
								Validators.required,
								Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
							])
						)
					})

					this.globals.hideSpinner()

					this.editProfileRef = this.modalService.show(content, { class: "fast-modal book-now-modal theme-modal", animated: true, backdrop: 'static', keyboard: false })
				}
				else {
					this.globals.hideSpinner()
					this.authService.logout()
					this.authService.updateCredentials()
				}
			})
		}
		else this.logout()
	}

	getImage(ev) {
		const file = ev.target.files[0]

		let exts = ['.png', '.jpg', '.jpeg', '.gif']
		if (exts.filter(ext => file.name.toLowerCase().endsWith(ext)).length == 0) {
			this.globals.showToast(this.translate.instant('invalidImage'), "error")
			return
		}

		if ((file.size / Math.pow(1024, 2)) > 2) {
			this.globals.showToast(this.translate.instant('invalidImage2'), "error")
			return
		}

		const reader = new FileReader()

		reader.readAsDataURL(file)
		reader.onload = () => {
			this.profilePicture = reader.result
			this.profilePicture64 = (<String>reader.result).split(',')[1]
		}
	}

	bytesToSize = (bytes) => {
		if (bytes == 0)
			return 0

		let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString())
		if (i == 0)
			return 0

		return (bytes / Math.pow(1024, i)).toFixed(1)
	}

	updateUserProfile(info) {
		if (this.userProfileForm.valid) {
			this.globals.showSpinner()

			if (this.password.old && this.password.new) {
				info["old_password"] = this.password.old;
				info["new_password"] = this.password.new;
			}

			if (this.profilePicture != this.globals.currentUser.user.avatar)
				info["avatar"] = this.profilePicture64

			if (this.doctorProfile.email == this.globals.currentUser.user.email)
				delete info["email"]

			info["country_id"] = this.doctorProfile.country_id
			info["city_id"] = this.doctorProfile.city_id
			info["street1"] = this.doctorProfile.street1
			info["apartment"] = this.doctorProfile.apartment
			info["building"] = this.doctorProfile.building
			info["floor"] = this.doctorProfile.floor

			this.doctorService.updateUserProfile(info).subscribe((res: any) => {
				this.globals.hideSpinner()
				if (res.type == 'success') {
					this.globals.secureStorage.setItem("currentUser", JSON.stringify(res));
					this.globals.currentUser = res;
					this.authService.updateCredentials();

					this.globals.showToast(this.translate.instant('profileUpdated'), 'success')
					this.editProfileRef.hide()
				}
				else this.globals.showToast(res.message, 'error')

				this.oldPasswordWrong = (res.error && res.error == "These credentials do not match our records.")
			},
				() => {
					this.globals.hideSpinner()
					this.globals.showToast(this.translate.instant('somethingWrong'), 'error')
				})
		}
		else this.globals.showToast(this.translate.instant('pleaseCheck'), 'error')
	}

	checkBasicInfo = () => (this.doctorProfile.name.trim() != "" && (this.password.old.trim() != "" || this.password.new.trim() != "" ? (this.password.new.trim() != "" && this.password.new.trim().length >= 6) : true)) &&
		(this.doctorProfile.name.trim().length >= 3 && this.doctorProfile.name.trim().length <= 30) &&
		!this.oldPasswordWrong && (this.password.old.trim() != "" ? (this.password.old.trim() != this.password.new.trim()) : true) &&
		(this.doctorProfile.city_id != -1 && this.doctorProfile.country_id != -1 && this.doctorProfile.street1 != "" && this.doctorProfile.apartment != "" && this.doctorProfile.building != "" && this.doctorProfile.floor != "")

	getNotifications = () => {
		this.notifications = []
		this.loadingNotifications = true
		this.unreadNotificationsCount = 0
		this.userService.getNotifications().subscribe((res: any) => {
			console.log('res',res);

			this.globals.currentUser.user.unread_notifications = 0
			this.globals.secureStorage.setItem("currentUser", JSON.stringify(this.globals.currentUser))
			if (res.type == 'success')
				this.notifications = res.notifications

			this.unreadNotificationsCount = this.notifications.filter(noti => noti.read_at == null).length
			this.loadingNotifications = false
		})
	}

	getMessages = (callback) => {
		this.messages = []
		this.loadingMessages = true
		this.unreadMessagesCount = 0
		this.userService.getMessages().subscribe((res: any) => {
			this.messages = res.filter(msg => msg.users.length > 0)
			this.messages = this.messages.map(msg => {
				if (msg.unread && msg.last_message == null)
					msg.unread = false
				return msg
			}).sort((msg1, msg2) => msg1.unread === msg2.unread ? 0 : (msg1.unread ? -1 : 1))

			this.unreadMessagesCount = this.messages.filter(msg => msg.unread == true).length
			this.loadingMessages = false

			if (callback)
				callback()
		})
	}

	newMessage = (content) => {
		this.globals.showSpinner()
		this.userService.newMessage().subscribe((res: any) => {
			this.participants = res.available_users

			this.globals.hideSpinner()
			this.newMessageRef = this.modalService.show(content, { class: "fast-modal theme-modal", animated: true })
		})
	}

	openThread = (content, id) => {
		this.currentThread = this.messages.filter(msg => msg.id == id)[0]
		if (this.currentThread.unread == true) {
			this.globals.currentUser.user.unread_messages = this.globals.currentUser.user.unread_messages - 1
			this.globals.secureStorage.setItem("currentUser", JSON.stringify(this.globals.currentUser))
		}

		this.loadingThread = true
		this.threadModalRef = this.modalService.show(content, { class: "fast-modal theme-modal", animated: true, backdrop: 'static', keyboard: false })

		this.userService.getThreadMessages(id).subscribe((res: any) => {
			this.currentThreadMessages = res

			this.loadingThread = false

			setTimeout(() => {
				let msgsCont = document.querySelector('.messages-container')
				msgsCont.scrollTo(0, msgsCont.scrollHeight)
			}, 200)
		})
	}

	sendNewMessage = () => {
		this.userService.sendMessage({ to: this.messageParticipant, message: this.messageBody }).subscribe((res: any) => { })
	}

	sendMessage = () => {
		let message = this.messageToSend
		message = message.replace(/\r?\n/g, '<br />').replace(/<(?!br\s*\/?)[^>]+>/g, '')
		this.messageToSend = ''
		this.sendingMessage = true

		console.log('userId', this.currentThread.users[0].id);
		let msg = {
			userId: this.currentThread.users[0].id,
			user_id: this.globals.currentUser.user.id,
			username: this.globals.currentUser.user.name,
			body: message,
			created_at: moment().format('YYYY-MM-DD HH:mm:ss')
		}
		this.currentThreadMessages.push(msg)

		setTimeout(() => {
			let msgsCont = document.querySelector('.messages-container')
			msgsCont.scrollTo(0, msgsCont.scrollHeight)
		}, 200)

		this.userService.sendMessage({ to: this.currentThread.users[0].id, message }).subscribe((res: any) => {
			if (res.type != "success") {
				this.currentThreadMessages.splice(this.currentThreadMessages.length - 1, 1)
				this.globals.showToast(this.translate.instant('somethingWrong'), 'error')
			}
			else {
				new Audio('assets/media/chat_msg_rec.mp3').play()
				this.globals.socket.emit('send', { ...msg, unread_messages: res.data })
			}

			this.sendingMessage = false
		})
	}

	closeThread = () => {
		this.currentThread = null
		this.currentThreadMessages = []
		this.loadingThread = false
		this.threadModalRef.hide()
	}

	getMessageParticipant = () => this.participants.filter(part => part.id == this.messageParticipant)[0]
	formatMessageDate = (datest) => moment(datest).fromNow()

	formatMessageDisplay = (msg) => {
		msg = msg.replace(/\<br \/\>/g, '...')
		msg = msg.length > 30 ? msg.substring(0, 30) + '...' : msg
		return msg
	}

	handleMessageInput = (ev: KeyboardEvent) => {
		if (ev.keyCode == 13 && !ev.altKey) {
			ev.preventDefault()
			if (this.messageToSend.trim().length > 0)
				this.sendMessage()
		}
		else if (ev.keyCode == 13 && ev.altKey) {
			(<HTMLTextAreaElement>ev.target).value = (<HTMLTextAreaElement>ev.target).value + '\n';
			(<HTMLTextAreaElement>ev.target).dispatchEvent(new Event('input'))
		}
	}

	resetImage = () => this.profilePicture = this.globals.currentUser.user.avatar

	checkStaticClass = () => !(['/landing', '/home', '/login', '/signup', '/doctor/signup', /auth\/account\-activation\/./, '/auth/reset-password'].filter(e => this.router.url.match(e)).length > 0) && !this.pageScrolled

	togglePassword = () => this.passwordToggle = !this.passwordToggle

	switchLanguage(lang: string) {
		document.location.reload()
		this.globals.currantLanguage = lang
		this.globals.secureStorage.setItem('current:lang', lang)
	}

	formatNumber = (number) => {
		number = parseFloat(number)
		if (typeof Intl == 'undefined' || !Intl)
			return new this.intl.NumberFormat().format(number)
		else return new Intl.NumberFormat().format(number)
	}

	parseTime = (time) => {
		let hour = time.split(':')[0]
		let minutes = time.split(':')[1]
		let ampm = 'AM'

		if (parseInt(hour) > 12) {
			ampm = 'PM'
			hour = parseInt(hour) - 12
		}

		return `${hour}:${minutes} ${ampm}`
	}

	getCurrentLanguage = () => this.translate.getDefaultLang() == 'ar' ? 'العربية' : 'English'

	showHowTo = () => {
		this.howToModalRef = this.modalService.show(this.howtoModal, { class: "fast-modal book-now-modal theme-modal modal-lg", animated: true })
	}

	getCities(countryId) {
		if (countryId > -1) {
			this.cms.getCities(countryId).subscribe((res: any) => {
				this.cities = res.cities
				this.doctorProfile.city_id = this.globals.currentUser.user.profile ? this.globals.currentUser.user.profile.city_id : -1
			})
		}
		else this.cities = []
	}

	checkPayment = () => this.doctorProfile.country_id > -1 &&
		this.doctorProfile.city_id > -1 &&
		this.doctorProfile.street1 != "" &&
		this.doctorProfile.apartment != "" &&
		this.doctorProfile.building != "" &&
		this.doctorProfile.floor != ""
}
