import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./pages/login/login.component";
import { SignupComponent } from "./pages/signup/signup.component";
import { DoctorSignupComponent } from "./pages/doctor-signup/doctor-signup.component";
import { CategoriesComponent } from "./pages/categories/categories.component";
import { DoctorProfileComponent } from "./pages/doctor-profile/doctor-profile.component";
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component";
import { EditDoctorProfileComponent } from "./pages/edit-doctor-profile/edit-doctor-profile.component";
import { UserAccountComponent } from "./pages/user-account/user-account.component";
import { UserPaymentComponent } from "./pages/user-payment/user-payment.component";
import { AuthGuard } from "./services/AuthGuard";
import { AuthGuardUser } from "./services/AuthGuardUser";
import { AuthGuardNotUser } from "./services/AuthGuardNotUser";
import { AuthGuardDoctor } from "./services/AuthGuardDoctor";
import { LandingComponent } from "./pages/landing/landing.component";
import { AboutUsComponent } from "./pages/about-us/about-us.component";
import { ContactUsComponent } from "./pages/contact-us/contact-us.component";
import { StreamComponent } from "./pages/stream/stream.component";
import { AfterPaymentComponent } from "./pages/after-payment/after-payment.component"
import { ResendCodeComponent } from "./pages/resend-code/resend-code.component";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { AllArticlesComponent } from "./pages/all-articles/all-articles.component";
import { ArticleComponent } from './pages/article/article.component';

const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  { path: "home", component: LandingComponent },
  { path: "login", component: LoginComponent, canActivate: [AuthGuardNotUser] },
  { path: "signup", component: SignupComponent, canActivate: [AuthGuardNotUser] },
  { path: "auth/account-activation/:token", component: ResendCodeComponent, canActivate: [AuthGuardNotUser] },
  { path: "auth/reset-password", component: ForgotPasswordComponent, canActivate: [AuthGuardNotUser] },
  { path: "about-us", component: AboutUsComponent },
  { path: "contact-us", component: ContactUsComponent },
  { path: "doctor/signup", component: DoctorSignupComponent, canActivate: [AuthGuardNotUser] },
  { path: "doctor/find", component: CategoriesComponent },
  { path: "doctor/find/:term", component: CategoriesComponent },
  { path: "doctor/profile/:id", component: DoctorProfileComponent },
  { path: "doctor/edit-profile", component: EditDoctorProfileComponent, canActivate: [AuthGuardDoctor] },
  { path: "doctor/sessions", component: UserAccountComponent, canActivate: [AuthGuardDoctor] },
  { path: "user/sessions", component: UserAccountComponent, canActivate: [AuthGuardUser] },
  { path: "user/balance", component: UserPaymentComponent, canActivate: [AuthGuardUser] },
  { path: "user/after_payment", component: AfterPaymentComponent },
  { path: "stream/:id", component: StreamComponent },
  { path: "articles", component: AllArticlesComponent },
  { path: "article/:id", component: ArticleComponent },
  { path: "notfound", component: PageNotFoundComponent },
  // { path: "**", component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
