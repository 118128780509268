import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from '../../services/Globals';
import { AuthService } from '../../services/auth.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
	selector: 'footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
	@ViewChild('privacyPolicyModal') privacyPolicyModal
	@ViewChild('termsModal') termsModal
	@ViewChild('returnsModal') returnsModal

	privacyPolicyRef: BsModalRef
	termsRef: BsModalRef
	returnsRef: BsModalRef

	constructor(public router: Router,
				private authService: AuthService,
				public translate: TranslateService,
				public globals: Globals,
				public modalService: BsModalService) { }

	ngOnInit() { }

	logout() {
		this.authService.logout()
		this.authService.updateCredentials()
		this.router.navigate(["/"])
	}

	showPrivacyPolicy = () => this.privacyPolicyRef = this.modalService.show(this.privacyPolicyModal, { class: "fast-modal theme-modal modal-lg", animated: true })
	showTerms = () => this.termsRef = this.modalService.show(this.termsModal, { class: "fast-modal theme-modal modal-lg", animated: true })
	showReturns = () => this.returnsRef = this.modalService.show(this.returnsModal, { class: "fast-modal theme-modal modal-lg", animated: true })

	checkLightClass = () => !(['/landing', '/home', '/login', '/signup', '/doctor/signup', '/contact-us', '/about-us', /auth\/account\-activation\/./, '/auth/reset-password'].filter(e => this.router.url.match(e)).length > 0)

}
