import { Component, OnInit, AfterContentInit, Renderer2, ViewEncapsulation, ViewChild, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs/Subject";
import { DoctorsService } from "../../services/doctors.service";
import { FilterService } from "../../services/filter.service";
import { Observable } from "rxjs/Observable";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import { StatesService } from "../../services/states.service";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { Globals } from "../../services/Globals";
import { Options } from 'ng5-slider';

@Component({
	selector: "app-categories",
	templateUrl: "./categories.component.html",
	styleUrls: ["./categories.component.scss"],
	encapsulation : ViewEncapsulation.None
})
export class CategoriesComponent implements OnInit, AfterContentInit, OnDestroy {
	@ViewChild('priceSlider') rangeSlider

	currentPage = 1
	lastPage = 1

	searchTerm$ = ''
	showSuggestions = false
	isList
	today = new Date()
	loading = true
	loadingMore = false
	noResult = false
	totalCount = 0

	Doctors = []
	diseases = []
	countries = []
	languages = []
	supervisors = []

	searchedTerm = ''
	category = 'all'
	supervisor = 'all'
	country = 'all'
	language = 'all'
	gender = 'all'
	isFree = false

	price_from = 0
	price_to = 1000
	options: Options = {
		floor: 0,
		ceil: 1000,
		step: 10,
		getPointerColor: () => '#3498db',
		noSwitching: true,
		minRange: 100
	}

	date_from
	date_to

	displayPicker = false

	reachedBottom = false

	dateRange: any = {
		beginDate: {
			year: this.today.getFullYear(),
			month: this.today.getMonth() + 1,
			day: this.today.getDate()
		},
		endDate: {
			year: this.today.getFullYear(),
			month: this.today.getMonth() + 2,
			day: this.today.getDate()
		}
	}

	scrollDetectVisible = false

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private DoctorsApi: DoctorsService,
		private filter: FilterService,
		private formBuilder: FormBuilder,
		private states: StatesService,
		private renderer: Renderer2,
		private titleService: Title,
		private translate: TranslateService,
		public globals: Globals
	) {
		translate.get('titles.findDoctors').subscribe(value => titleService.setTitle(value))
		if (translate.getDefaultLang() == 'ar')
			this.options.rightToLeft = true
	}

	ngOnInit() {
		this.searchedTerm = this.route.snapshot.paramMap.get("term")

		this.filter.getFilterData().subscribe((res: any) => {
			console.log('res',res);
			this.countries = res.countries
			this.diseases = res.categories
			this.languages = res.languages
			this.supervisors = res.supervisors
		})
		// this.filter.allSupervisors().subscribe((res: any) => {
		// 	console.log('res',res);
		// 	this.supervisors = res.supervisors
		// })

		this.getDoctors()

		this.searchForDoctors(this.searchTerm$)

		window.onscroll = () => {
			let scrollPos = ((<HTMLElement>document.querySelector('#scrollDetect')).offsetTop + 129) - window.scrollY - document.documentElement.clientHeight

			if (scrollPos < 0) {
				if (!this.scrollDetectVisible) {
					this.loadMore()
					this.scrollDetectVisible = true
				}
			}
			else this.scrollDetectVisible = false
		}

		setTimeout(() => {
			window.dispatchEvent(new Event('resize'))
		}, 100)

		this.rangeSlider.onChangeValue = () => this.getDoctors()
		this.rangeSlider.onChangeHighValue = () => this.getDoctors()
	}

	ngOnDestroy() {
		window.onscroll = () => {}
	}

	ngAfterContentInit() {
		const datePickerElement = document.querySelector(
			"ngbd-datepicker-range ngb-datepicker"
		)
		this.renderer.addClass(datePickerElement, "date-picker");
	}

	getRangeDateString = () => `${this.dateRange.beginDate.day}/${this.dateRange.beginDate.month}/${this.dateRange.beginDate.year} - ${this.dateRange.endDate.day}/${this.dateRange.endDate.month}/${this.dateRange.endDate.year}`

	inputChanged = event => {
		if (event.code == 'Enter' || event.code == 13)
			this.searchForDoctors(this.searchedTerm)
	}

	getSearchOptions = () => {
		return {
			price_from: this.price_from,
			price_to: this.price_to,
			...(this.searchedTerm == '' ? {} : { search: this.searchedTerm }),
			...(this.date_from == null ? {} : { date_from: this.date_from }),
			...(this.date_to == null ? {} : { date_to: this.date_to }),
			...(this.category == 'all' ? {} : { category_id: this.category }),
			...(this.country == 'all' ? {} : { country_id: this.country }),
			...(this.language == 'all' ? {} : { language_id: this.language }),
			...(this.gender == 'all' ? {} : { gender: this.gender }),
			...(this.supervisor == 'all' ? {} : { supervisor_id: this.supervisor }),
			free: this.isFree
		}
	}

	searchForDoctors(term) {
		this.searchedTerm = term;
		this.DoctorsApi.getDoctors(this.getSearchOptions()).subscribe((res: any) => {
			this.Doctors = res.data;
			console.log('Doctors',this.Doctors);

			if (this.Doctors.length === 0) {
				this.noResult = true;
			} else {
				this.noResult = false;
			}

			this.totalCount = res.meta.total
		});
	}

	withinDate(date) {
		if (date.from && date.to) {
			this.date_from = this.formateDate(date.from);
			this.date_to = this.formateDate(date.to);
			this.getDoctors();
		}
	}

	resetFilter() {
		this.searchedTerm = ''
		this.category = 'all'
		this.country = 'all'
		this.language = 'all'
		this.gender = 'all'
		this.price_from = 0
		this.price_to = 5000
		this.date_from = null
		this.date_to = null
		this.isFree = false

		this.getDoctors();
	}

	getDoctors() {
		// console.log('free session');
		// console.log(this.isFree);


		if (navigator.userAgent.indexOf('Edge') < 0)
			document.body.scrollTo(0, 0)
		this.loading = true
		this.Doctors = []

		this.DoctorsApi.getDoctors(this.getSearchOptions()).subscribe((res: any) => {
			console.log('res',res);

			this.loading = false

			this.Doctors = res.data
			if (this.Doctors.length === 0) {
				this.noResult = true
			} else {
				this.noResult = false
			}

			this.totalCount = res.meta.total
			this.lastPage = res.meta.last_page
		})
		console.log(this.getSearchOptions());

	}

	rating = (rate) => Array(rate ? parseInt(rate) : 0)

	loadMore = () => {
		if (this.lastPage > this.currentPage) {
			this.loadingMore = true
			this.currentPage++
			this.DoctorsApi.loadMore(this.getSearchOptions(), this.currentPage).subscribe((res: any) => {
				res.data.forEach(doctor => this.Doctors.push(doctor))
				this.lastPage = res.meta.last_page
				this.loadingMore = false
			},
			() => this.loadingMore = false)
		}
	}

	formateDate(date) {
		if (date.day.toString().length < 2) {
			date.day = `0${date.day}`;
		}
		if (date.month.toString().length < 2) {
			date.month = `0${date.month}`;
		}

		return `${date.year}-${date.month}-${date.day}`;
	}
}
