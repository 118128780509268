import { Component, OnInit, AfterContentInit } from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService } from '../../services/payment.service';
import { Globals } from '../../services/Globals';

@Component({
	selector: 'app-after-payment',
	templateUrl: './after-payment.component.html',
	styleUrls: ['./after-payment.component.scss']
})
export class AfterPaymentComponent implements AfterContentInit {
	status = "pending"

	constructor(private spinnerService: Ng4LoadingSpinnerService, private globals: Globals, private route: ActivatedRoute, public router: Router, private paymentService: PaymentService) { }

	ngAfterContentInit() {
		if (this.globals.currentUser) {
			//this.confirm()
			if (this.route.snapshot.queryParams["captured_amount"] != null && this.route.snapshot.queryParams["captured_amount"] !== undefined) {
				this.router.navigate([`user/balance`], { queryParams: this.route.snapshot.queryParams })
			}
			else {
				this.router.navigateByUrl('/home')
			}
		}
		else {
			if (this.route.snapshot.queryParams["captured_amount"] != null && this.route.snapshot.queryParams["captured_amount"] !== undefined) {
				this.globals.showSpinner()
				this.paymentService.rechargePaymobConfirmApp(location.href, this.route.snapshot.queryParams["merchant_order_id"]).subscribe(res => {
					this.status = 'success'
					this.globals.hideSpinner()
				}, () => {
					this.status = 'failed'
					this.globals.hideSpinner()
				})
			}
			else {
				this.router.navigateByUrl('/home')
			}
		}
	}

	// confirm = () => {
	// 	this.globals.showSpinner()

	// 	const state = this.route.snapshot.queryParams["state"]
	// 	const token = this.route.snapshot.queryParams["token"]

	// 	let data = {
	// 		paymentToken: this.route.snapshot.queryParams["token"],
	// 		PayerID: this.route.snapshot.queryParams["PayerID"],
	// 		paymentId: this.route.snapshot.queryParams["paymentId"],
	// 	}

	// 	if (state === "success") {
	// 		this.paymentService.captureSuccessPayment(data, token).subscribe((res: any) => {
	// 			if (res.status == "SUCCESS") {
	// 				this.status = 'success'
	// 			}
	// 			this.globals.hideSpinner()
	// 		},
	// 		() => {
	// 			this.globals.hideSpinner()
	// 			this.status = 'failed'
	// 		})
	// 	}
	// 	else if (state === "failed") {
	// 		this.status = 'failed'
	// 		this.globals.hideSpinner()
	// 		// this.paymentService.captureFailedPayment(data, token).subscribe((res: any) => {
	// 		// 	if (!res.message)
	// 		// 		this.status = 'failed'
	// 		// 	this.globals.hideSpinner()
	// 		// },
	// 		// () => {
	// 		// 	this.globals.hideSpinner()
	// 		// 	this.status = 'failed'
	// 		// })
	// 	}
	// }

}
