import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Globals } from "./Globals";
import { BehaviorSubject } from "rxjs";
import { Observable } from "rxjs/Observable";

@Injectable()
export class AuthService {
  loginState$ = new BehaviorSubject<any>(this.globals.currentUser);

  constructor(private api: ApiService, private globals: Globals) {}

  login = (email, password) =>
    this.api.anyFetch(this.api.urls.login, {
      email: email,
      password: password
    })

  before_register = () => this.api.anyFetch(this.api.urls.before_register, null, 'get')
  register = data => this.api.anyFetch(this.api.urls.register, data)
  verifyAccount = (data, _token) => this.api.onlyFetch(this.api.urls.registerContinue, data, 'get', _token)
  resendCode = (_token) => this.api.onlyFetch(this.api.urls.resendCode, null, 'get', _token)

  forgotPassword = (data) => this.api.anyFetch(this.api.urls.forgotPassword, data)
  forgotPasswordActivate = (data) => this.api.anyFetch(this.api.urls.forgotPasswordActivate, data)

  logout = () => {
    this.globals.secureStorage.removeItem("currentUser")
    this.globals.currentUser = null
  }

  updateCredentials() {
    this.loginState$.next(this.globals.currentUser);
  }

  checkAuth(): Observable<any> {
    return this.loginState$;
  }

  me = () => this.api.onlyFetch(this.api.urls.me, {data: 123}, 'get')
}
