import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http'
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Globals } from './Globals';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private router: Router, private authService: AuthService, private spinnerService: Ng4LoadingSpinnerService, private globals: Globals) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).do(event => {}, err => {
            this.globals.hideSpinner()
            if (err instanceof HttpErrorResponse && err.status == 401) {
                this.authService.logout();
		        this.authService.updateCredentials();
                this.router.navigate(['/login'])
            }
        })
    }
}