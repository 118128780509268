import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "monthToString"
})
export class MonthToStringPipe implements PipeTransform {
  months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  transform(value: number, args?: any): any {
    return this.months[value - 1];
  }
}
