import { Injectable, EventEmitter } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import * as CryptoJS from 'crypto-js';
import * as SecureStorage from 'secure-web-storage';
import { Events } from "ionic-angular";
declare var io: any;

@Injectable()
export class Globals {
	currantLanguage:any

	constructor(private toastr: ToastrService, public events: Events) {
		// console.log(this.secureStorage.getItem('current:lang') );
		this.currantLanguage = this.secureStorage.getItem('current:lang')
		// console.log('currantLanguage',this.currantLanguage);
	 }

	secureStorage = new SecureStorage(localStorage, {
		hash: function hash(key) {
			key = CryptoJS.SHA256(key, 'ubtXFG468b');

			return key.toString();
		},
		encrypt: function encrypt(data) {
			data = CryptoJS.AES.encrypt(data, 'ubtXFG468b');

			data = data.toString();

			return data;
		},
		decrypt: function decrypt(data) {
			data = CryptoJS.AES.decrypt(data, 'ubtXFG468b');

			data = data.toString(CryptoJS.enc.Utf8);

			return data;
		}
	})

	currentUser: any = JSON.parse(this.secureStorage.getItem("currentUser")) || null
	Echo: any
	rtcMultiConnection: any
	socket: any

	currency = 'EGP'

	initSocketConnection = () => {
		// this.rtcMultiConnection = new RTCMultiConnection()
		// this.rtcMultiConnection.enableLogs = true
		// // this.rtcMultiConnection.socketURL = 'https://stream.psycounslers.com:9001/';
		// this.rtcMultiConnection.socketURL = 'https://20146f83.ngrok.io/';
		// this.rtcMultiConnection.socketOptions = {
		// 	'force new connection': true,
		// 	'forceNew': true,
		// 	'transport': 'polling',
		// 	'query': `userId=${this.currentUser.user.id}`
		// }

		// this.rtcMultiConnection.connectSocket()
		// this.socket = this.rtcMultiConnection.socket
		// this.rtcMultiConnection.userid = this.currentUser.user.id
		if (io && io !== undefined) {
			this.socket = io('https://psycounslers.com:2000', { 'query': `userId=${this.currentUser.user.id}` })

			this.socket.on('receive', data => this.events.publish('msg-receive', data))
			this.socket.on('userReservationMade', data => {
				console.log('reservation data',data);

				this.events.publish('reservation-made', data)
			})
			this.socket.on('sessionStarted', data => this.events.publish('session-started', data))
		}
	}

	public isArabic = (text) => /[\u0600-\u06FF]/.test(text)

	showToast = (message, type, options = null) => {
		if (type == 'success')
			this.toastr.success(message, null, { preventDuplicates: true, positionClass: 'toast-bottom-center', enableHtml: true, ...(options ? options : {}) })
		else if (type == 'info')
			this.toastr.info(message, null, { preventDuplicates: true, positionClass: 'toast-bottom-center', enableHtml: true, ...(options ? options : {}) })
		else if (type == 'error')
			this.toastr.error(message, null, { preventDuplicates: true, positionClass: 'toast-bottom-center', enableHtml: true, ...(options ? options : {}) })
	}

	showSpinner = () => document.querySelector('ng4-loading-spinner .spinner').classList.remove('hidden')
	hideSpinner = () => document.querySelector('ng4-loading-spinner .spinner').classList.add('hidden')

	isDoctorActive = () => this.currentUser ? (this.currentUser.user.doctor ? this.currentUser.user.doctor.is_verified : false) : false
}
