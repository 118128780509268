import { Component, OnInit, AfterContentInit, Renderer2, ViewChild } from "@angular/core";
import { ApiService } from "../../services/api.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DoctorsService } from "../../services/doctors.service";
import { Globals } from "../../services/Globals";
import { AuthService } from "../../services/auth.service";
import { Title } from "@angular/platform-browser";
import { CmsService } from "../../services/cms.service";
import { FilterService } from "../../services/filter.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute } from "../../../../node_modules/@angular/router";
declare var moment: any;
declare var libphonenumber: any;
import { extendMoment } from 'moment-range';
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { Events } from "ionic-angular";
// import { BsModalService, BsModalRef } from 'ngx-bootstrap';

@Component({
	selector: "app-edit-doctor-profile",
	templateUrl: "./edit-doctor-profile.component.html",
	styleUrls: ["./edit-doctor-profile.component.scss"]
})
export class EditDoctorProfileComponent implements OnInit {
	@ViewChild('howToModal') howtoModal

	howToModalRef: BsModalRef

	howtoIndex = 0
	howtoTitle = ''

	moment

	profilePicture
	profilePicture64

	idPicture
	photoCard = null
	photoCardGloabal
	certificatesPhotos = []
	certificatesPhotosGlobal = []
	certificatesPictures_front = []
	photoCardFlag:boolean = false

	tab = 1
	passwordToggle = true
	oldPasswordWrong = false
	scheduleTab = 1

	doctorProfile = {
		title: "",
		name: "",
		email: "",
		description: "",
		about: "",
		country: -1,
		city: -1,
		session_price: 0
	}

	languages = []
	specialities = []
	countries = []
	cities = []

	BasicInfoForm;
	newLanguage = -1
	newSpeciality = -1
	selectedLanguages = []
	selectedSpecialities = []
	scheduleHistory = null
	scheduleHistoryDefault = null
	selectedDay = null
	selectedSchedule = []
	isFree = false
	currentTime = "1"
	currentMinutes = "0"
	isAM = false

	password = {
		old: "",
		new: ""
	}
	certificates = []
	newCertificate = ''
	date
	time = {
		hours: 0,
		minutes: 0
	}

	currentDate = null
	minDate
	maxDate

	defaultValues = null

	formattedPhone = ''
	phoneCountries = []
	phoneUpdate = ''
	phoneCountry = 64

	isUpdatingPhone = false

	deletedScheduleItem = false

	constructor(
		private api: ApiService,
		private cms: CmsService,
		private filterService: FilterService,
		private doctorService: DoctorsService,
		private renderer: Renderer2,
		public globals: Globals,
		private authService: AuthService,
		private titleService: Title,
		private spinnerService: Ng4LoadingSpinnerService,
		private translate: TranslateService,
		public route: ActivatedRoute,
		private modalService: BsModalService,
		public events: Events
	) {
		translate.get('titles.editMyProfile').subscribe(value => titleService.setTitle(value))

		route.params.subscribe(params => this.tab = params['_tab'] == 'schedule' ? 3 : 1)

		this.moment = extendMoment(moment)
	}

	ngOnInit() {
		if(this.globals.currentUser.user.doctor.photocard){
			this.photoCardGloabal =  this.globals.currentUser.user.doctor.photocard.split('/')
		}

		setTimeout(() => {
			 this.certificatesPhotosGlobal = this.globals.currentUser.user.doctor.certificates_files.map(i=>{
				return  <Object>{ id: i.id, src: i.src,name:i.src.split('/') }
			})
			// console.log('this.certificatesPhotosGlobal',this.certificatesPhotosGlobal);
			// console.log('photoCardGloabal',this.photoCardGloabal);

		}, 500);



		this.doctorProfile.name = this.globals.currentUser.user.name;
		this.doctorProfile.description = this.globals.currentUser.user.description;
		this.doctorProfile.email = this.globals.currentUser.user.email;
		this.profilePicture = this.globals.currentUser.user.avatar;
		this.formattedPhone = new libphonenumber.AsYouType().input(this.globals.currentUser.user.phone)

		this.globals.showSpinner()
		this.doctorService
			.getOneDoctor(this.globals.currentUser.user.id)
			.subscribe((res: any) => {
				this.defaultValues = res.data

				this.doctorProfile.session_price = res.data.doctor.session_price ? res.data.doctor.session_price : 0

				this.doctorProfile.about = res.data.doctor.about
				this.doctorProfile.city = res.data.doctor.city_id

				this.doctorProfile.country = res.data.doctor.country_id
				if (res.data.doctor.country_id)
					this.getCities(res.data.doctor.country_id)

				this.doctorProfile.description = res.data.doctor.description == null ? "" : res.data.doctor.description
				this.doctorProfile.email = res.data.email
				this.doctorProfile.title = res.data.doctor.title
				this.doctorProfile.name = res.data.name

				this.certificates = res.data.doctor.certificates.slice()
				this.selectedLanguages = res.data.languages.slice()
				this.selectedSpecialities = res.data.categories.slice()
			});

		this.refreshScheduleHistory()

		this.authService.before_register().subscribe((res: any) => {
			this.phoneCountries = res.countries
		})

		this.BasicInfoForm = new FormGroup({
			title: new FormControl(this.doctorProfile.title, Validators.required),
			name: new FormControl(this.doctorProfile.name, Validators.required),
			description: new FormControl(
				this.doctorProfile.name,
				Validators.required
			),
			email: new FormControl(
				this.doctorProfile.email,
				Validators.compose([
					Validators.required,
					Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
				])
			),
			session_price: new FormControl(this.doctorProfile.session_price, Validators.compose([
				Validators.required,
				Validators.min(0)
			]))
		});

		this.filterService.getFilterData().subscribe((res: any) => {
			let body = document.getElementById('main-body')
			if (body.classList.contains('rtl')) {
				console.log('contains');

			}
			this.languages = res.languages
			this.countries = res.countries
			this.specialities = res.categories
		})

		let now = moment()
		let end = moment().endOf('year')
		this.minDate = { year: now.year(), month: now.month() + 1, day: now.date() }
		this.maxDate = { year: end.year(), month: end.month() + 1, day: end.date() }

		this.events.subscribe('refresh-schedule', () => {
			this.refreshScheduleHistory(false)
		})
	}

	isPhoneValid = () => {
		return !this.phoneUpdate.startsWith('0') && /^\d+$/.test(this.phoneUpdate) && libphonenumber.isValidNumber(`+${this.countries.filter(country => country.id == this.phoneCountry)[0].phonecode}${this.phoneUpdate}`)
	}

	updateBasicInfo(info) {
		this.globals.showSpinner()

		let formData = new FormData();

		if (this.password.old && this.password.new) {
			formData.append('old_password', this.password.old)
			formData.append('new_password', this.password.new)
		}

		if (this.profilePicture !== this.globals.currentUser.user.avatar) {
			formData.append('avatar', this.profilePicture64)
		}

		if (this.photoCard && this.photoCardFlag === false) {
			formData.append("photo_card", this.photoCard)
		}

		this.certificatesPhotos.forEach((file,index) => {
			// formData.append('certificates', file)
			formData.append(`certificates[${index}]`, file)
			// formData.append(files[${index}], file)
		})
		// formData.append('certificates', JSON.stringify(this.certificatesPhotos))


		formData.append('name', info.name)
		formData.append('title', info.title)
		formData.append('description', info.description)
		this.photoCardFlag = false
		this.doctorService.updateBasicInfo(formData).subscribe((res: any) => {
			if (res.type === "success") {
				this.globals.secureStorage.setItem("currentUser", JSON.stringify(res))
				this.globals.currentUser = res
				this.authService.updateCredentials()

				this.globals.showToast(this.translate.instant('profileUpdated'), 'success')
				this.certificatesPhotos = []

				this.photoCard = null
				this.ngOnInit()
			}
			else this.globals.showToast(res.error, 'error')

			this.oldPasswordWrong = (res.error && res.error == "These credentials do not match our records.")

			this.globals.hideSpinner()
		}, (err: any) => {
			this.globals.hideSpinner()
			this.globals.showToast(this.translate.instant('somethingWrong'), 'error')
		})

		console.log('this.photoCardFlag',this.photoCardFlag);
	}

	deleteFile = (index) => {
		console.log('index',typeof index);
		if(index != undefined) {
			this.certificatesPhotos.splice(index,1)
			if(index === 0) {
				(<HTMLInputElement>document.getElementById('certificantPicturesInput')).value = ''
			}
		} else {
			this.photoCard = null;
			(<HTMLInputElement>document.getElementById('idPictureInput')).value = ''
		}
	}
	deleteDoctorFiles = (index) => {
		this.globals.showSpinner()

		let selectedFile = this.globals.currentUser.user.doctor.certificates_files[index]
		console.log('selectedFile',selectedFile);

		this.doctorService.deleteFile(selectedFile.id).subscribe( (res: any) =>{
			if (res.type === "success") {
				this.ngOnInit()
				this.globals.currentUser.user.doctor.certificates_files.splice(index,1)
				setTimeout(() => {
					this.globals.showToast(this.translate.instant('fileDeleted'), 'success')
				}, 200);
			}
			else this.globals.showToast(res.error, 'error')
			this.globals.hideSpinner()
		}, (err: any) => {
			this.globals.hideSpinner()
			this.globals.showToast(this.translate.instant('somethingWrong'), 'error')
		})

	}
	deleteDoctorCard = () => {
		this.photoCardFlag = true
		document.getElementById('photocardID').innerHTML = ''
		this.photoCardGloabal[this.photoCardGloabal.length -1] = ''
	}
	checkBasicInfo = () => (this.doctorProfile.name != "" && this.doctorProfile.description != "" && this.doctorProfile.session_price > -1 && (this.password.old != "" ? (this.password.new != "" && this.password.new.length >= 6) : true)) &&
		(this.doctorProfile.name.length >= 3 && this.doctorProfile.name.length <= 30) &&
		(this.doctorProfile.description ? (this.doctorProfile.description.length >= 10 &&
			this.doctorProfile.description.length <= 40) : true)

	// 	&&!this.oldPasswordWrong && (this.password.old != "" ? (this.password.old != this.password.new) : true)
	checkBasicInfo1 = () => {
		if (this.doctorProfile.name != "" && this.doctorProfile.name.length >= 3 && this.doctorProfile.name.length <= 30) {
			return true
		}
		if (this.password.old != "" && this.password.new != "" && this.password.new.length >= 6) {
			return true
		}
	}

	undoBasicInfo = () => {
		this.doctorProfile.description = this.defaultValues.doctor.description
		this.doctorProfile.email = this.defaultValues.email
		this.doctorProfile.name = this.defaultValues.name
		this.password.new = ""
		this.password.old = ""
	}

	updateProfileInfo(info) {
		this.globals.showSpinner()
		const updates = {
			about: this.doctorProfile.about,
			certificates: this.certificates,
			languages: this.selectedLanguages.map(item => item.id),
			categories: this.selectedSpecialities.map(item => item.id),
			country_id: this.doctorProfile.country,
			city_id: this.doctorProfile.city
		}

		this.doctorService.updateProfileInfo(updates).subscribe((res: any) => {
			if (res.data)
				this.globals.showToast(this.translate.instant('accountDataUpdated'), 'success')
			else this.globals.showToast(this.translate.instant('somethingWrong'), 'error')

			this.globals.hideSpinner()
		},
			(err: any) => {
				this.globals.hideSpinner()
				this.globals.showToast(this.translate.instant('somethingWrong'), 'error')
			})
	}

	undoProfileInfo = () => {
		this.doctorProfile.about = this.defaultValues.doctor.about
		this.doctorProfile.city = this.defaultValues.doctor.city_id

		this.doctorProfile.country = this.defaultValues.doctor.country_id
		if (this.defaultValues.doctor.country_id)
			this.getCities(this.defaultValues.doctor.country_id)

		this.certificates = this.defaultValues.doctor.certificates.slice()
		this.selectedLanguages = this.defaultValues.languages.slice()
		this.selectedSpecialities = this.defaultValues.categories.slice()
	}

	checkProfileInfo = () => this.doctorProfile.about != "" && this.certificates.length > 0 && this.selectedLanguages.length > 0 && this.selectedSpecialities.length > 0 && this.doctorProfile.country > -1 && this.doctorProfile.city > -1

	submitSchedule = () => {
		this.globals.showSpinner()

		let dates = []
		let keys = this.getKeys(this.scheduleHistory)
		keys.forEach(key => this.scheduleHistory[key].forEach(schedule => dates.push(schedule.data)))

		dates = [
			...dates,
			...this.selectedSchedule
		]

		this.doctorService.updateDoctorDates(dates).subscribe((res: any) => {
			this.selectedSchedule = []
			this.isFree = false
			this.currentTime = "1"
			this.selectedDay = null

			this.globals.showToast(this.translate.instant('yourScheduleUpdated'), 'success')
			this.scheduleHistory = res
			this.scheduleHistoryDefault = JSON.parse(JSON.stringify(res))
			this.globals.hideSpinner()
		},
			(error: any) => {
				this.globals.showToast(this.translate.instant('somethingWrong'), 'error')
				this.globals.hideSpinner()
			})
	}

	submitScheduleHistory = () => {
		this.globals.showSpinner()

		let dates = []
		let keys = this.getKeys(this.scheduleHistory)
		keys.forEach(key => this.scheduleHistory[key].forEach(schedule => dates.push(schedule.data)))

		this.doctorService.updateDoctorDates(dates).subscribe((res: any) => {
			this.globals.showToast(this.translate.instant('yourScheduleUpdated'), 'success')
			this.scheduleHistory = res
			this.globals.hideSpinner()
			this.deletedScheduleItem = false
		},
			() => {
				this.globals.showToast(this.translate.instant('somethingWrong'), 'error')
				this.globals.hideSpinner()
			})
	}

	removeSession = (month, i) => {
		console.log(month);
		console.log(i);
		console.log(this.scheduleHistory[month]);

		this.scheduleHistory[month].splice(i, 1)

		if (this.scheduleHistory[month].length == 0)
			delete this.scheduleHistory[month]
		this.deletedScheduleItem = true
	}

	refreshScheduleHistory = (show = false) => {
		if (show)
			this.globals.showSpinner()
		this.doctorService.getAvailableDates().subscribe((res: any) => {
			this.scheduleHistory = res
			this.scheduleHistoryDefault = JSON.parse(JSON.stringify(res))
			this.globals.hideSpinner()
		})
		this.deletedScheduleItem = false
	}

	undoScheduleHistory = () => {
		this.scheduleHistory = JSON.parse(JSON.stringify(this.scheduleHistoryDefault))
		this.deletedScheduleItem = false
	}

	onDateSelect = (date) => {
		this.isAM = false
		this.selectedDay = date
		setTimeout(() => {
			this.currentTime = (<HTMLOptionElement>document.querySelector('#hour option:not([hidden])')).value
			this.currentMinutes = (<HTMLOptionElement>document.querySelector('#minutes option:not([hidden])')).value
		}, 50)
	}

	addScheduleTime = () => {
		let dates = JSON.parse(JSON.stringify(this.selectedSchedule))
		let keys = this.getKeys(this.scheduleHistory)
		keys.forEach(key => this.scheduleHistory[key].forEach(schedule => {
			let date = moment(schedule.data.timestamp)
			dates.push({
				year: date.year(),
				month: date.month(),
				day: date.date(),
				hour: date.hour(),
				minutes: date.minutes()
			})
		}))

		let checkDates = dates.filter((date, pos) =>
			(Math.abs(moment(date).diff(moment({ year: this.selectedDay.year, month: this.selectedDay.month - 1, day: this.selectedDay.day, hour: this.fixHour(parseInt(this.currentTime)), minutes: this.currentMinutes }))) < 3600000)
			// dates.filter((date2, pos2) => pos == pos2 ? false : Math.abs(moment(date).diff(moment(date2))) < 3600000).length > 0
		)
		if (checkDates.length > 0) {
			this.globals.showToast(this.translate.instant('youAlreadySelectedCurrent'), "error")
			return
		}

		let selectedDayMoment = moment({ year: this.selectedDay.year, month: this.selectedDay.month - 1, day: this.selectedDay.day, hour: this.fixHour(parseInt(this.currentTime)), minutes: this.currentMinutes })
		this.selectedSchedule.push({
			year: selectedDayMoment.year(),
			month: selectedDayMoment.month(),
			day: selectedDayMoment.date(),
			hour: selectedDayMoment.hour(),
			minutes: selectedDayMoment.minutes(),
			timestamp: selectedDayMoment.format('Y-MM-DD HH:mm:ss'),
			is_free: this.isFree
		})
	}

	removeScheduleTime = (index) => this.selectedSchedule.splice(index, 1)

	highlightDays = () => {
		if (this.selectedSchedule.length == 0) {
			const list = document.querySelectorAll('ngb-datepicker div[ng-reflect-current-month')
			for (let i = 0; i < list.length; i++) {
				this.renderer.removeClass(list[i], "bg-bluish-grey")
				this.renderer.removeClass(list[i], "text-white")
			}

			return
		}

		this.selectedSchedule.forEach(schedule => {
			const list = document.querySelectorAll(`ngb-datepicker div[ng-reflect-current-month="${schedule.month}"]`)
			const days = this.selectedSchedule.filter(schedule2 => schedule2.month == schedule.month).map(el => schedule.day)

			for (let i = 0; i < list.length; i++) {
				if (days.includes(parseInt(list[i].textContent, 0)) && !list[i].classList.contains("outside")) {
					this.renderer.addClass(list[i], "bg-bluish-grey")
					this.renderer.addClass(list[i], "text-white")
				}
			}
		})
	}

	getImage(ev) {
		const file = ev.target.files[0]

		let exts = ['.png', '.jpg', '.jpeg', '.gif']
		if (exts.filter(ext => file.name.toLowerCase().endsWith(ext)).length === 0) {
			this.globals.showToast(this.translate.instant('invalidImage'), "error")
			return
		}

		if ((file.size / Math.pow(1024, 2)) > 2) {
			this.globals.showToast(this.translate.instant('invalidImage2'), "error")
			return
		}

		const reader = new FileReader()

		reader.readAsDataURL(file)
		reader.onload = () => {
			this.profilePicture = reader.result
			this.profilePicture64 = reader.result
		}
	}


	getIdImage(ev) {
		this.photoCardFlag === false

		const file = ev.target.files[0]

		let exts = ['.png', '.jpeg', '.bmp']
		if (exts.filter(ext => file.name.toLowerCase().endsWith(ext)).length === 0) {
			this.globals.showToast(this.translate.instant('invalidImage'), "error")
			return
		}

		if ((file.size / Math.pow(1024, 2)) > 2) {
			this.globals.showToast(this.translate.instant('invalidImage2'), "error")
			return
		}

		this.photoCard = file
		console.log('this.photoCard',this.photoCard);

		const reader = new FileReader()

		reader.readAsDataURL(file)
		reader.onload = () => {
			this.idPicture = reader.result
		}

		console.log((<HTMLInputElement>document.getElementById('idPictureInput')).value)
		   console.log('this.photoCardFlag',this.photoCardFlag);

	}

	getCities(countryId) {
		if (countryId > -1) {
			this.cms.getCities(countryId).subscribe((res: any) => {
				this.cities = res.cities
				this.doctorProfile.city = this.defaultValues.doctor.city_id
			})
		}
		else this.cities = []
	}
	getCertificantImage(ev: FileList) {
		let files = Object.values(ev)

		let exts = ['.png', '.jpeg', '.bmp','.pdf','.jpg']
		files.forEach(file=> {
			if (exts.filter(ext => file.name.toLowerCase().endsWith(ext)).length === 0) {
				this.globals.showToast(this.translate.instant('invalidImage'), "error")
				return
			}
		})

		this.certificatesPhotos = files

		files.forEach(file => {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => {
				let image = (<String>reader.result).split(",")[1]
				// console.log();

				this.certificatesPictures_front.push(reader.result)
			}
			// console.log('this.certificatesPictures_front',this.certificatesPictures_front);

		})
			console.log((<HTMLInputElement>document.getElementById('certificantPicturesInput')).value)

	}
	addCertificate() {
		if (this.newCertificate.length > 0) {
			this.certificates.push(this.newCertificate);
			this.newCertificate = ''
		}
	}

	removeCertificate = (idx) => this.certificates.splice(idx, 1)
	clearCertificates = () => this.certificates = []

	addLanguage() {
		if (this.newLanguage > -1) {
			this.selectedLanguages.push(this.languages.filter(lang => lang.id == this.newLanguage)[0])
			this.newLanguage = -1
		}
	}

	addSpeciality() {
		if (this.newSpeciality > -1) {
			this.selectedSpecialities.push(this.specialities.filter(spec => spec.id == this.newSpeciality)[0])
			this.newSpeciality = -1
		}
	}

	removeLanguage = (idx) => this.selectedLanguages.splice(idx, 1)
	clearLanguages = () => this.selectedLanguages = []

	removeSpeciality = (idx) => this.selectedSpecialities.splice(idx, 1)
	clearSpecialities = () => this.selectedSpecialities = []

	availableLanguages = () => this.languages.filter(lang => !this.selectedLanguages.map(lang2 => lang2.id).includes(lang.id))
	availableSpecialities = () => this.specialities.filter(spec => !this.selectedSpecialities.map(spec2 => spec2.id).includes(spec.id))

	togglePassword = () => this.passwordToggle = !this.passwordToggle

	showHowTo = (index, title) => {
		this.howtoIndex = index
		this.howtoTitle = this.translate.instant(title)
		this.howToModalRef = this.modalService.show(this.howtoModal, { class: "fast-modal book-now-modal theme-modal modal-lg", animated: true })
	}

	canAddHour = (hour) => {
		let now = moment()
		if (this.selectedDay.year == now.year() &&
			this.selectedDay.month == now.month() + 1 &&
			this.selectedDay.day == now.date()) {
			hour = this.fixHour(hour)
			return hour > now.hour() ? true : (hour >= now.hour()) && now.minutes() < 45
		}
		else return true
		// return moment().diff(moment({ year: this.selectedDay.year, month: this.selectedDay.month, day: this.selectedDay.day, hour: this.fixHour(hour), minutes: this.currentMinutes })) < 0
	}

	canAddMinutes = (minutes) => {
		let now = moment()
		if (this.selectedDay.year == now.year() &&
			this.selectedDay.month == now.month() + 1 &&
			this.selectedDay.day == now.date()) {
			let hour = this.fixHour(parseInt(this.currentTime))
			return hour > now.hour() ? true : minutes > now.minutes()
		}
		else return true
		// return moment().diff(moment({ year: this.selectedDay.year, month: this.selectedDay.month, day: this.selectedDay.day, hour: this.fixHour(parseInt(this.currentTime)), minutes })) < 0
	}

	canAM = () => {
		let now = moment()
		if (this.selectedDay.year == now.year() &&
			this.selectedDay.month == now.month() + 1 &&
			this.selectedDay.day == now.date()) {
			return now.hour() < 12
		}
		else return true
	}

	fixHour = (hour) => this.isAM ? (hour == 12 ? 0 : hour) : (hour == 12 ? hour : hour + 12)

	addOneHour = (hour) => moment().set({ hour: hour }).add({ hour: 1 }).hour()

	getKeys = (obj) => {
		if (obj) {
			let newCopy = JSON.parse(JSON.stringify(obj))
			return Object.keys(newCopy).filter(month => moment(`${month} 1 2018`).month() >= moment().month())
		}

		return []
	}

	localeMonth = (timestamp) => moment(timestamp).format('MMMM')
	localeMonthOnly = (month) => moment(`1 ${month} 2018`).format('MMMM')

	parseTime = (timestamp, addHour = false) => moment(timestamp).add({ hour: addHour ? 1 : 0 }).format('hh:mm A')
}
