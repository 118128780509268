import { Injectable } from "@angular/core";
import {
	CanActivate,
	Router,
	ActivatedRouteSnapshot,
	RouterStateSnapshot
} from "@angular/router";
import { AuthService } from "./auth.service";
import { Globals } from "./Globals";

@Injectable()
export class AuthGuardDoctor implements CanActivate {
	constructor(private globals: Globals, private router: Router) { }

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean {
		const url: string = state.url;

		return this.checkLogin(url);
	}

	checkLogin(url: string): boolean {
		if (this.globals.currentUser != null && this.globals.currentUser.user.role.name == 'doctor') {
			return true;
		}

		this.router.navigate(["/notfound"]);
		return false;
	}
}
