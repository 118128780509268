import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators, AbstractControl } from "@angular/forms";
import { CmsService } from "../../services/cms.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { Globals } from "../../services/Globals";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
declare var fbq: any;

@Component({
  selector: "app-contact-us",
  templateUrl: "./contact-us.component.html",
  styleUrls: ["./contact-us.component.scss"]
})
export class ContactUsComponent implements OnInit {
  contactForm : FormGroup
  email = ''
  name = ''
  subject = ''
  message = ''
  messageLength = 720

	constructor(private cms: CmsService,
				private spinnerService: Ng4LoadingSpinnerService,
				private globals: Globals,
				private titleService: Title,
				private translate: TranslateService) {
		translate.get('titles.contactUs').subscribe(value => titleService.setTitle(value))
	}

  ngOnInit() {
    this.contactForm = new FormGroup({
      email: new FormControl(
        "",
        Validators.compose([
          Validators.required, 
          Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        ])
      ),
      full_name: new FormControl("", Validators.required),
      subject: new FormControl("", Validators.required),
      message: new FormControl(
        "",
        Validators.compose([Validators.required, Validators.maxLength(720)])
      ),
      news_subscribe: new FormControl(false)
    });
  }

  sendMessage(details) {
		this.globals.showSpinner()
		this.cms.contactUs(details).subscribe((res: any) => {
			if (res.data) {
				this.globals.showToast(this.translate.instant('keepInTouch'), 'success')
			}
			else this.globals.showToast(this.translate.instant('somethingWrong'), 'error')

			fbq('track', 'Contact')

			this.contactForm.reset()
			this.email = ''
			this.name = ''
			this.subject = ''
			this.message = ''
			
			this.globals.hideSpinner()
		},
		() => this.globals.showToast(this.translate.instant('somethingWrong'), 'error'))
  }
  
  formValid = () => this.email.trim() != "" &&
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.email) &&
  this.name.trim() != "" && this.subject.trim() != "" && this.message.trim() != ""
}
