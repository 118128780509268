import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Globals } from "./Globals";
import { TranslateService } from "@ngx-translate/core";
declare var moment: any;
declare var require: any;

@Injectable()
export class ApiService {
	url = "https://psycounslers.com:49753/api";
	// url = "http://192.168.1.5/psycounselor-backend/public/api";
	role = this.globals.currentUser ? this.globals.currentUser.user.role.name : ""

	urls = {
		login: `${this.url}/auth/login`,
		register: `${this.url}/auth/register`,
		before_register: `${this.url}/auth/before_register`,
		registerContinue: `${this.url}/user/register/continue`,
		resendCode: `${this.url}/resend_code`,
		me: `${this.url}/auth/me`,
		contact_us: `${this.url}/contact_us`,
		doctors: `${this.url}/all-doctors`,
		getOneDoctor: `${this.url}/doctor/`,
		getAvailableDates: `${this.url}/doctor/get_doctor_dates`,
		updateDoctorBasicInfo: `${this.url}/doctor/update_info`,
		updateDoctorProfileInfo: `${this.url}/doctor/update_profile_info`,
		updateUserProfile: `${this.url}/user/update`,
		getReservations: `${this.url}/user/init_reservation`,
		reserve: `${this.url}/user/reservation`,
		getFreeReservations: `${this.url}/user/init_free_reservation`,
		freeReserve: `${this.url}/user/free_reservation`,
		notifications: `${this.url}/user/notifications`,
		threads: `${this.url}/user/threads`,
		thread: `${this.url}/user/thread/`,
		read_thread: `${this.url}/user/thread_as_read/`,
		send_message: `${this.url}/user/message`,
		new_message: `${this.url}/user/new_message`,
		updateDoctorDates: `${this.url}/doctor/update_doctor_dates`,
		getCities: `${this.url}/cities`,
		diseases: `${this.url}/diseases`,
		doctorReviews: `${this.url}/reviews/doctor/`,
		reservations: `${this.url}/${this.role}/reservations`,
		reservations_pag: `${this.url}/${this.role}/reservations/paginated`,
		allSessions: `${this.url}/${this.role}/sessions`,

		paymentPaymob: `${this.url}/payment/paymob/init`,
		paymentPaymobConfirm: `${this.url}/payment/paymob/success`,

		paymentHyper: `${this.url}/payment/hyperpay`,
		paymentHyperConfirm: `${this.url}/payment/hyperpay/confirm`,
		paymentHyperCancel: `${this.url}/payment/hyperpay/cancel`,

		paymentHistory: `${this.url}/user/payment_history`,

		payment: `${this.url}/payment/paypal/init`,
		checkPayment: `${this.url}/payment/paypal/success`,
		paypal_success: `${this.url}/payment/paypal/success`,
		paypal_cancel: `${this.url}/payment/paypal/cancel`,

		getFilterData: `${this.url}/get_filter_data`,
		checkSession: `${this.url}/session/check`,
		startSession: `${this.url}/session/start`,
		endSession: `${this.url}/session/end`,
		submitReview: `${this.url}/user/submit-review`,
		landingData: `${this.url}/landing_page`,
		forgotPassword: `${this.url}/auth/forget_password`,
		forgotPasswordActivate: `${this.url}/auth/three_aces/activate`,
		getTimezone: `${this.url}/get_timezone`,
		allArticles: `${this.url}/articles`,
		article: `${this.url}/articles`,
		useGiftCard: `${this.url}/user/apply-gift-card`,
		deleteFile:`${this.url}/doctor/delete-image/`,
		supervisor:`${this.url}/user/supervisor/all`,
		paymentAppComplete: `${this.url}/payment/paymob/mobile`
	};

	timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

	constructor(
		private http: HttpClient,
		private globals: Globals,
		public translate: TranslateService
	) {
		if (typeof Intl == 'undefined' || !Intl) {
			let intl = require('intl')
			this.timezone = intl.DateTimeFormat().resolvedOptions().timeZone
		}
		else this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
	}

	updateUrls = role => {
		this.role = role;
		this.urls.reservations = `${this.url}/${role}/reservations`;
		this.urls.allSessions = `${this.url}/${role}/sessions`;
	}

	anyFetch = (url, body, mehtod = "post", isFormData = false) => {
		if (!isFormData) {
			body = {
				...body,
				timezone: this.timezone ? this.timezone : (this.globals.currentUser ? this.globals.currentUser.timezone : ''),
				lang: this.translate.getDefaultLang()
			}
		}
		else {
			(<FormData>body).append('timezone', this.timezone ? this.timezone : (this.globals.currentUser ? this.globals.currentUser.timezone : ''));
			(<FormData>body).append('lang', this.translate.getDefaultLang())
		}

		return mehtod === "post"
			? this.http.post(url, body)
			: this.http.get(url, {
				params: body
			});
		}

	onlyFetch = (url, body, mehtod = "post", _token = null, isFormData = false) => {
		if (!isFormData) {
			body = {
				...body,
				timezone: this.timezone ? this.timezone : (this.globals.currentUser ? this.globals.currentUser.timezone : ''),
				lang: this.translate.getDefaultLang()
			}
		}
		else {
			(<FormData>body).append('timezone', this.timezone ? this.timezone : (this.globals.currentUser ? this.globals.currentUser.timezone : ''));
			(<FormData>body).append('lang', this.translate.getDefaultLang())
		}

		return mehtod === "post"
			? this.http.post(
				url, body,
				{
					headers: {
						Authorization: `Bearer ${
							_token ? _token : this.globals.currentUser.access_token
							}`
					}
				}
			)
			: this.http.get(url, {
				headers: {
					Authorization: `Bearer ${
						_token ? _token : this.globals.currentUser.access_token
						}`
				},
				params: body
			});
		}

	getTimezone = () => this.anyFetch(this.urls.getTimezone, null, 'get')
}
