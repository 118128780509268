import { Directive, HostListener, AfterContentInit, EventEmitter, Output } from '@angular/core';
import { Subject} from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[coTracker]'
})

export class CoTrackerDirective implements AfterContentInit {

  // Basic
  @Output('hover') hover = new EventEmitter;


  @Output('panStarted') panStarted = new EventEmitter;
  @Output('released') released = new EventEmitter;
  @Output('hold') hold = new EventEmitter;

  public $pointerHover = new Subject<PointerEvent>();
  public $pointerOut = new Subject<PointerEvent>();
  public $pointerDown = new Subject<PointerEvent>();
  public $pointerMoves = new Subject<PointerEvent>();
  public $pointerUp = new Subject<PointerEvent>();


  @HostListener('pointerover', ['$event']) pointerOver (ev: PointerEvent) {
    this.$pointerHover.next(ev);
  }

  @HostListener('pointerout', ['$event']) pointerOut (ev: PointerEvent) {
    this.$pointerOut.next(ev);
  }

  @HostListener('pointerdown', ['$event']) pointerDown (ev: PointerEvent) {
    this.$pointerDown.next(ev);
  }

  @HostListener('document:pointerup', ['$event']) pointerUp (ev: PointerEvent) {
    this.$pointerUp.next(ev);
  }

  @HostListener('document:pointermove', ['$event']) pointerMove (ev: PointerEvent) {
    this.$pointerMoves.next(ev);
  }

  constructor() {}

  ngAfterContentInit() {

    // Track user panning
   this.$pointerDown.asObservable().pipe(
      switchMap(() => this.$pointerMoves.pipe(takeUntil(this.$pointerUp))
      )
    ).subscribe(ev => {
      this.panStarted.emit(ev);
  });


  // Detect holding
  this.$pointerDown.asObservable().subscribe(ev => {
    this.hold.emit(ev);
  });

  // Detect poiner up
  this.$pointerUp.asObservable().subscribe(ev => {
    this.released.emit(ev);
  });


  // Detect hovering
  this.$pointerHover.asObservable().subscribe(ev => {
    this.hover.emit(ev);
  });

  // Detect Outside host
  this.$pointerOut.asObservable().subscribe(ev => {
    this.released.emit(ev);
  });

}


}
