import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { Globals } from "../../services/Globals";
import { ISubscription } from "rxjs/Subscription";
import { Title } from "@angular/platform-browser";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
// import { isValidNumber } from 'libphonenumber-js'
import { TranslateService } from "@ngx-translate/core";
import { ApiService } from "../../services/api.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
declare var AOS: any;
declare var libphonenumber: any;
declare var fbq: any;

@Component({
	selector: "signup",
	templateUrl: "./signup.component.html",
	styleUrls: ["./signup.component.scss"]
})
export class SignupComponent implements OnInit, OnDestroy {
	@ViewChild('privacyPolicyModal') privacyPolicyModal
	@ViewChild('termsModal') termsModal

	privacyPolicyRef: BsModalRef
	termsRef: BsModalRef

	signupForm
	verifyForm
	gender
	loginSubscription: ISubscription
	formValid = false
	phone = ''
	code = ''
	loginData
	passwordToggle = true

	verifyPhone = false
	resendActivationCode = false
	resendActivationCodeInterval
	resendActivationCodeTime = 60

	countries = []

	constructor(
		private authService: AuthService,
		private apiService: ApiService,
		private globals: Globals,
		private router: Router,
		private titleService: Title,
		private spinnerService: Ng4LoadingSpinnerService,
		private translate: TranslateService,
		private modalService: BsModalService
	) {
		translate.get('titles.register').subscribe(value => titleService.setTitle(value))
	}

	ngOnInit() {
		AOS.init()

		this.loginSubscription = this.authService
			.checkAuth()
			.subscribe(isLoggedIn => {
				if (isLoggedIn) {
					this.router.navigate(["/"])
				}
			})

		this.globals.showSpinner()
		this.authService.before_register().subscribe((res: any) => {
			this.countries = res.countries
			this.globals.hideSpinner()
		})

		this.signupForm = new FormGroup({
			name: new FormControl("", Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(30)
			])),
			email: new FormControl(
				"",
				Validators.compose([
					Validators.required,
					Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
				])
			),
			phone: new FormControl("", this.validatePhone),
			countryCode: new FormControl(64),
			gender: new FormControl("Male", Validators.required),
			password: new FormControl(
				"",
				this.validatePassword
			),
			checkLicense: new FormControl(true)
		})
	}

	ngOnDestroy() {
		this.loginSubscription.unsubscribe();
	}

	validatePhone = (c: FormControl) => {
		if (this.signupForm && this.countries.length > 0)
			if (!c.value.startsWith('0') && /^\d+$/.test(c.value))
				return !libphonenumber.isValidNumber(`+${this.countries.filter(country => country.id == this.signupForm.controls.countryCode.value)[0].phonecode}${c.value}`) ? { validPhone: true } : null
			else return { validPhone: true }
		return null
	}

	validatePassword = (c: FormControl) => {
		if (this.signupForm)
			return !(/[^\s]/.test(this.signupForm.controls.password.value.trim()) && this.signupForm.controls.password.value.trim().length >= 8) ? { validPassword: true } : null
		return null
	}

	verify() {
		this.globals.showSpinner()
		this.authService.verifyAccount({ code: this.code }, this.loginData.access_token).subscribe((res: any) => {
			if (res.user) {
				fbq('track', 'CompleteRegistration')

				this.loginData.user = res.user
				this.globals.secureStorage.setItem("currentUser", JSON.stringify(this.loginData))
				this.globals.currentUser = this.loginData
				this.apiService.updateUrls(this.loginData.user.role.name)
				this.authService.updateCredentials()
				this.router.navigate(["/"])
			}
			else this.globals.showToast(this.translate.instant('somethingWrong'), 'error')

			this.globals.hideSpinner()
		},
			() => {
				this.globals.showToast(this.translate.instant('somethingWrong'), 'error')
				this.globals.hideSpinner()
			})
	}

	resendActivation = (resend) => {
		this.resendActivationCodeTime = 60
		this.resendActivationCode = false

		this.resendActivationCodeInterval = setInterval(() => {
			this.resendActivationCodeTime--
			if (this.resendActivationCodeTime == 0) {
				clearInterval(this.resendActivationCodeInterval)
				this.resendActivationCode = true
			}
		}, 1000)

		if (resend) {
			this.globals.showSpinner()
			this.authService.resendCode(this.loginData.access_token).subscribe((res: any) => {
				if (res.message && res.message == 'unauthorized') {
					this.globals.showToast(this.translate.instant('somethingWrong'), 'error')
					this.router.navigate(["/signup"])
				}
				else {
					this.resendActivationCodeTime = 60
					this.resendActivationCode = false
					this.globals.showToast(this.translate.instant('checkTheVerification'), 'success')
				}

				this.globals.hideSpinner()
			},
				() => {
					this.globals.showToast(this.translate.instant('somethingWrong'), 'error')
					this.globals.hideSpinner()
				})
		}
	}

	signup(data) {
		let newData = JSON.parse(JSON.stringify(data))
		newData.phone = this.signupForm.controls.phone.value
		newData['country_id'] = this.signupForm.controls.countryCode.value

		this.globals.showSpinner()
		this.authService.register(newData).subscribe((res: any) => {
			if (res.type === "error") {
				this.globals.showToast(res.message == 'unauthorized' ? this.translate.instant('thisAccount') : res.message, 'error')
			} else {
				if (res.user.is_verified) {
					fbq('track', 'CompleteRegistration')

					this.loginData = res
					this.globals.secureStorage.setItem("currentUser", JSON.stringify(this.loginData))
					this.globals.currency = res.user.currency
					this.globals.currentUser = this.loginData
					this.apiService.updateUrls(this.loginData.user.role.name)
					this.authService.updateCredentials()
					this.router.navigate(["/"])
				}
				else {
					this.globals.showToast(this.translate.instant('checkTheVerification'), 'success')
					this.verifyPhone = true
					this.loginData = res
					this.resendActivation(false)
				}
			}

			this.globals.hideSpinner()
		},
			(e: any) => {
				// console.log(e);
				let body = document.getElementById('main-body')
				if (body.classList.contains('rtl')) {


					if (e.error && e.error.errors) {

						let errors = Object.keys(e.error.errors).map(key => e.error.errors[key])
						console.log(errors);
						errors.forEach((res) => {
							if (res.includes("The email has already been taken.")) {
								this.globals.showToast('هذا الايميل مستخدم من قبل .', 'error')

							}
							if (res.includes("The phone has already been taken.")) {
								this.globals.showToast('هذا الهاتف مستخدم من قبل .', 'error')

							}

						})
					}
					else this.globals.showToast(this.translate.instant('somethingWrong'), 'error')
					this.globals.hideSpinner()

				} else {
					if (e.error && e.error.errors) {
						let errors = Object.keys(e.error.errors).map(key => e.error.errors[key])
						errors.forEach(element => this.globals.showToast(element, 'error'))
					}
					else this.globals.showToast(this.translate.instant('somethingWrong'), 'error')
					this.globals.hideSpinner()
				}

			})
	}

	showPrivacyPolicy = () => this.privacyPolicyRef = this.modalService.show(this.privacyPolicyModal, { class: "fast-modal theme-modal modal-lg", animated: true })
	showTerms = () => this.termsRef = this.modalService.show(this.termsModal, { class: "fast-modal theme-modal modal-lg", animated: true })
}
