import {
	Component,
	OnInit,
	Renderer2,
	ViewChild,
	ElementRef,
	AfterViewInit
} from "@angular/core";
import { FakerService } from "../../services/faker.service";
import {
	trigger,
	style,
	animate,
	transition,
	stagger,
	query
} from "@angular/animations";
import { CmsService } from "../../services/cms.service";
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";
import { Globals } from "../../services/Globals";
import { BsModalRef, BsModalService } from "../../../../node_modules/ngx-bootstrap";
declare var AOS: any;
declare var require: any;

@Component({
	selector: "app-landing",
	templateUrl: "./landing.component.html",
	styleUrls: ["./landing.component.scss"]
})
export class LandingComponent implements OnInit, AfterViewInit {
	readMoreModalRef: BsModalRef
	@ViewChild('videoPlayer') videoPlayer: ElementRef

	categories = []
	selectedCategory
	selectedVideo
	selectedVideoScreenshot
	// crew_videos = [
	// 	{
	// 		title: "من هو الأخصائي النفسي؟",
	// 		url: "https://www.youtube.com/embed/3hMcAMnHqqk?rel=0&showinfo=0&allowfullscreen=0&autoplay=1",
	// 		screenshot: "https://i2.ytimg.com/vi/3hMcAMnHqqk/maxresdefault.jpg",
	// 		description: `تعريف الأخصائي النفسي ودوره في حل مشاكل الأسرة. تعريف الأخصائي النفسي ودوره في حل مشاكل الأسرة. تعريف الأخصائي النفسي ودوره في حل مشاكل الأسرة. تعريف الأخصائي النفسي ودوره في حل مشاكل الأسرة.`
	// 	},
	// 	{
	// 		title: "كيف تؤثر الصحة النفسية علي الصحة الجسدية ؟",
	// 		url: "https://www.youtube.com/embed/UY5Vp1_nEiE?rel=0&showinfo=0&allowfullscreen=0&autoplay=1",
	// 		screenshot: "https://i2.ytimg.com/vi/UY5Vp1_nEiE/maxresdefault.jpg",
	// 		description: `أهمية الصحة النفسية وتأثيرها على حياتنا اليومية أهمية الصحة النفسية وتأثيرها على حياتنا اليومية أهمية الصحة النفسية وتأثيرها على حياتنا اليومية أهمية الصحة النفسية وتأثيرها على حياتنا اليومية`
	// 	},
	// 	{
	// 		title: "تعريف | 'المعالج النفسي' وكيف تتم جلسات العلاج النفسي",
	// 		url: "https://www.youtube.com/embed/1JuEzQo0ykY?rel=0&showinfo=0&allowfullscreen=0&autoplay=1",
	// 		screenshot: "https://i2.ytimg.com/vi/1JuEzQo0ykY/maxresdefault.jpg",
	// 		description: `في هذا الفيديو ستتعرف على العلاج النفسي وكيف تتم جلسات العلاج النفسي في هذا الفيديو ستتعرف على العلاج النفسي وكيف تتم جلسات العلاج النفسي في هذا الفيديو ستتعرف على العلاج النفسي وكيف تتم جلسات العلاج النفسي في هذا الفيديو ستتعرف على العلاج النفسي وكيف تتم جلسات العلاج النفسي`
	// 	}
	// ];

	packages = []
	relatedDoctors = []
	articles = []

	aboutMore = false

	intl

	constructor(
		private faker: FakerService,
		private cms: CmsService,
		private renderer: Renderer2,
		private titleService: Title,
		public translate: TranslateService,
		public globals: Globals,
		private modalService: BsModalService
	) {
		if (typeof Intl == 'undefined' || !Intl)
			this.intl = require('intl')

		translate.get('titles.home').subscribe(value => titleService.setTitle(value))
		document.body.style.overflow = 'hidden'
	}

	ngOnInit() {
		this.cms.getLandingInfo().subscribe((res: any) => {
			this.articles = res.data.articles

			this.globals.currency = res.data.currency

			res.data.categories
				.filter(category => {
					if (category.name && category.details.length > 0) {
						return category;
					}
				})
				.forEach(category => {
					this.categories.push(category);
				});

			res.data.packages.forEach(packageDetails => {
				this.packages.push(packageDetails);
			})

			// this.least_price = res.data.least_price;

			this.selectedCategory = this.categories[0]
			this.relatedDoctors = this.categories[0].doctors
			console.log('this.relatedDoctors',this.relatedDoctors);
			
			this.renderVideo()

			AOS.init({
				once: true
			})

			document.body.style.overflow = 'auto'
			document.querySelector('.home-loading').remove()
		})

		// this.selected_crew_video = this.crew_videos[0];
	}

	ngAfterViewInit() {
		this.renderVideo()
	}

	subDescription = (desc: String) => desc.replace(/\r?\n/g, ' ').replace(/(<([^>]+)>)/ig, ' ').replace(/&nbsp;/g, ' ').substr(0, 80) + '...'

	rating = (rate) => Array(rate ? parseInt(rate) : 0)

	showLess(txt, count) {
		if (txt) {
			let trimmedString = "";

			const original = txt.split(" ");

			for (let i = 0; i < count; i++) {
				trimmedString += ` ${original[i]}`;
			}

			trimmedString += "...";

			return trimmedString;
		}

		return ''
	}

	setCategory(id) {
		this.selectedCategory = this.categories.filter(category => {
			return category.id === id;
		})[0];

		this.relatedDoctors = this.selectedCategory.doctors;

		this.renderVideo();

		// this.mainVideoIsPlayed = false;
	}


	// setVideo(idx) {
	// 	const prevVideoIdx = this.crew_videos.indexOf(this.selected_crew_video);
	// 	const currentVideoIdx = idx;

	// 	// Replace the selected with previous video
	// 	this.crew_videos[prevVideoIdx] = this.selected_crew_video;
	// 	this.selected_crew_video = this.crew_videos[currentVideoIdx];
	// 	this.crew_videos[currentVideoIdx] = this.crew_videos[prevVideoIdx];

	// 	this.crewVideoIsPlayed = false;
	// }

	private renderVideo() {
		if (this.selectedCategory) {
			const videoId = this.selectedCategory.details[0].details.video_url.split("=")[1];
			this.selectedVideo = `https://www.youtube.com/embed/${videoId}?rel=0&showinfo=0&allowfullscreen=0&autoplay=1`;
			const screenshot = `https://i2.ytimg.com/vi/${videoId}/maxresdefault.jpg`;

			this.renderer.setStyle(this.videoPlayer.nativeElement, 'background', `url(${screenshot}) no-repeat center top`)
			this.selectedVideoScreenshot = screenshot;
		}
	}

	showMore = (content) => {
		this.readMoreModalRef = this.modalService.show(content, { class: "theme-modal book-now-modal modal-xl", animated: true })
	}

	share = (type, article) => {
		let link = `https://psycounselor.com/#/article/${article.id}`
		if (type == 'facebook')
			window.open(`http://www.facebook.com/sharer.php?u=${link}`)
		else if (type == 'twitter')
			window.open(`https://twitter.com/share?url=${link}&text=${article.title}&hashtags=psycounselor`)
		else if (type == 'googleplus')
			window.open(`https://plus.google.com/share?url=${link}`)
		else if (type == 'mail')
			document.location.href = `mailto:?Subject=${article.title}&Body=${link}%0D%0A%0D%0Ahttp://psycounselor.com`
	}

	formatNumber = (number) => {
		number = parseFloat(number)
		if (typeof Intl == 'undefined' || !Intl)
			return new this.intl.NumberFormat().format(number)
		else return new Intl.NumberFormat().format(number)
	}

	checkArabic = (text) => /[\u0600-\u06FF]/.test(text)

	getCurrentLanguage = () => this.translate.getDefaultLang()
}
