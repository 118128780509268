import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ThrowStmt } from "@angular/compiler";

@Injectable()
export class StatesService {
  //////////////////////
  // Navbar style state
  //////////////////////

 transparentWith = ['/home', '/login' , '/signup'];

}
