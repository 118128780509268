import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../../services/Globals';
import { AuthService } from '../../services/auth.service';
import { ApiService } from '../../services/api.service';

@Component({
	selector: 'app-resend-code',
	templateUrl: './resend-code.component.html',
	styleUrls: ['./resend-code.component.scss']
})
export class ResendCodeComponent implements OnInit {
	token = ''
	code = ''
	resendActivationCode = false
	resendActivationCodeInterval
	resendActivationCodeTime = 60

	constructor(
		private authService: AuthService,
		private apiService: ApiService,
		private globals: Globals,
		private router: Router,
		private route: ActivatedRoute,
		private titleService: Title,
		private spinnerService: Ng4LoadingSpinnerService,
		private translate: TranslateService
	) {
		this.token = this.route.snapshot.params.token
	}

	ngOnInit() {
		this.resendActivation(true)
	}

	verify() {
		this.globals.showSpinner()
		this.authService.verifyAccount({ code: this.code }, this.token).subscribe((res: any) => {
			if (res.user) {
				this.globals.secureStorage.setItem("currentUser", JSON.stringify(res))
				this.globals.currentUser = res
				this.apiService.updateUrls(res.user.role.name)
				this.authService.updateCredentials()
				this.router.navigate(["/"])
			}
			else this.globals.showToast(this.translate.instant('somethingWrong'), 'error')

			this.globals.hideSpinner()
		},
		() => {
			this.globals.showToast(this.translate.instant('somethingWrong'), 'error')
			this.globals.hideSpinner()
		})
	}

	resendActivation = (resend) => {
		this.resendActivationCodeTime = 60
		this.resendActivationCode = false

		this.resendActivationCodeInterval = setInterval(() => {
			this.resendActivationCodeTime--
			if (this.resendActivationCodeTime == 0) {
				clearInterval(this.resendActivationCodeInterval)
				this.resendActivationCode = true
			}
		}, 1000)

		if (resend) {
			this.globals.showSpinner()
			this.authService.resendCode(this.token).subscribe((res: any) => {
				if (res.message && res.message == 'unauthorized') {
					this.globals.showToast(this.translate.instant('somethingWrong'), 'error')
					this.router.navigate(["/signup"])
				}
				else {
					this.resendActivationCodeTime = 60
					this.resendActivationCode = false
					this.globals.showToast(this.translate.instant('checkTheVerification'), 'success')
				}

				this.globals.hideSpinner()
			},
			() => {
				this.globals.showToast(this.translate.instant('somethingWrong'), 'error')
				this.globals.hideSpinner()
			})
		}
	}

}
