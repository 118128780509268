import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-page-not-found',
	templateUrl: './page-not-found.component.html',
	styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

	constructor(private titleService: Title, private translate: TranslateService) {
		translate.get('titles.pageNotFound').subscribe(value => titleService.setTitle(value))
	}

	ngOnInit() { }
}
