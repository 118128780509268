import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ApiService } from './api.service'
import { Globals } from './Globals';

@Injectable()
export class FilterService {
  constructor (private http: HttpClient, private api: ApiService,private globals:Globals) {}

  allDiseases = () => {
    return this.http.get(this.api.urls.diseases)
  }
  // allSupervisors = () => {
  //   return this.http.get(this.api.urls.supervisor,{
	// 				headers: { 'Authorization': `Bearer ${this.globals.currentUser.access_token}`}
	// 			})
  // }

  getFilterData = () => this.api.anyFetch(this.api.urls.getFilterData, {}, 'get')
  // allSupervisors = () => this.api.anyFetch(this.api.urls.supervisor, {}, 'get',false)
}
