import {
  Component,
  AfterContentInit,
  Input,
  Renderer2,
  HostListener,
  ViewChild,
  ElementRef,
  ContentChildren
} from "@angular/core";

// Boundaries interface
interface Boundaries {
  maxX: number;
  minX: number;
}

@Component({
  selector: "co-showcase-item",
  template: `<ng-content></ng-content>`
})
export class CoShowcaseItemComponent {}

@Component({
  selector: "co-showcase",
  templateUrl: "co-showcase.component.html",
  styleUrls: ["co-showcase.component.scss"]
})
export class CoShowcaseComponent implements AfterContentInit {
  @Input("fade") fade = false;
  @Input("fadeStart") fadeStart = 30;
  @Input("fadeEnd") fadeEnd = 70;
  @ContentChildren(CoShowcaseItemComponent, { read: ElementRef })
  showcaseItems;

  private shift;
  private currentPostion = 0;
  private pointer = { currentPostion: 0, previousPosition: 0 };
  private boundaries = { minX: 0, maxX: 0, minY: 0, maxY: 0 };
  private showcaseRect;
  private contentRect;
  private panMode = false;
  private firstElement;
  private lastElement;

  @ViewChild("content") content: ElementRef;
  @ViewChild("mask") mask: ElementRef;

  @HostListener("window:resize", ["$event"])
  onresize(ev) {
    this.initialize();
  }


  constructor(public element: ElementRef, private renderer: Renderer2) {}



  ngAfterContentInit() {
    // Initialize
    setTimeout(() => {
      this.initialize();
    }, 250);
  }




  // Start panning function
  panStarted(ev) {
    if (this.panMode) {
      this.currentPostion += ev.movementX;
      this.renderer.setStyle(
        this.content.nativeElement,
        "transform",
        `translateX(${this.currentPostion}px)`
      );
    }
  }

  // End pan function
  released(ev) {
    // If content exceeded the boundaries, move to the boundary position;
    this.checkBoundariesExceeding();
  }

  // Freezing function
  freez(ev) {
    this.currentPostion = ev.layerX - (ev.target.offsetLeft + ev.offsetX);
    this.renderer.setStyle(
      this.content.nativeElement,
      "transform",
      `translateX(${this.currentPostion}px)`
    );
  }

  // Initilaize function
  initialize() {
    this.showcaseRect = this.element.nativeElement.getBoundingClientRect();
    this.contentRect = this.content.nativeElement.getBoundingClientRect();
    this.shift = (this.showcaseRect.width - this.contentRect.width) / 2;



    if (this.contentRect.width > this.showcaseRect.width) {
      this.panMode = true;
    }








    // if (this.contentRect.width > this.showcaseRect.width) {
    //   this.panMode = true;
    // } else {
    //   this.panMode = false;
    // }

    // Translate elements
    this.renderer.setStyle(
      this.content.nativeElement,
      "transform",
      "translateX(0)"
    );

    // Set background mode
    if (this.fade === true) {
      this.renderer.setStyle(
        this.mask.nativeElement,
        "-webkit-mask",
        `linear-gradient(to right, transparent 0%, black ${
          this.fadeStart
        }%, black ${this.fadeEnd}%, transparent 100%)`
      );
    }
  }

  // Check boundaries exceeding function
  private checkBoundariesExceeding(): boolean {
    const firstElementWidth = this.showcaseItems.first
      ? this.showcaseItems.first.nativeElement.clientWidth
      : "0";
    const lastElementWidth = this.showcaseItems.last
      ? this.showcaseItems.last.nativeElement.clientWidth
      : "0";

    // if fade mode is active
    if (this.fade) {
      this.boundaries.minX = -(
        Math.abs(this.shift * 2) +
        this.showcaseRect.width / 2 -
        lastElementWidth
      );
      this.boundaries.maxX = this.showcaseRect.width / 2 - firstElementWidth;
    } /*if fade mode is inactive*/ else {
      this.boundaries.minX = -Math.abs(this.shift * 2);
      this.boundaries.maxX = 0;
    }

    if (this.currentPostion < this.boundaries.minX) {
      this.currentPostion = this.boundaries.minX;
      this.renderer.setStyle(
        this.content.nativeElement,
        "transform",
        `translateX(${this.currentPostion}px)`
      );
      return true;
    } else if (this.currentPostion > this.boundaries.maxX) {
      this.currentPostion = this.boundaries.maxX;
      this.renderer.setStyle(
        this.content.nativeElement,
        "transform",
        `translateX(${this.currentPostion}px)`
      );
      return true;
    }
  }
}
