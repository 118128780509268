import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "./api.service";
import { Globals } from "./Globals";
import {Observable} from "rxjs"

@Injectable()
export class UserService {
  constructor(private http: HttpClient, private api: ApiService, private globals: Globals) { }

  getNotifications = () => this.api.onlyFetch(this.api.urls.notifications, {}, 'get')

  getMessages = () => this.api.onlyFetch(this.api.urls.threads, {}, 'get')
  sendMessage = (data) => this.api.onlyFetch(this.api.urls.send_message, data)
  newMessage = () => this.api.onlyFetch(this.api.urls.new_message, {}, 'get')
  getThreadMessages = id => this.api.onlyFetch(this.api.urls.thread + id, {}, 'get')
  readThread = id => this.api.onlyFetch(this.api.urls.read_thread + id, {}, 'get')

  reservations = (body):any =>{
     return this.http.get(this.api.urls.reservations, {
        headers: {
          Authorization: `Bearer ${
            this.globals.currentUser.access_token
            }`
        },
        params:body
  
      });
  //  return this.api.onlyFetch(this.api.urls.reservations, {}, "get");
  }
  // getDoctors = options => this.http.post(this.api.urls.doctors, options);
  loadMore( page) {
    return this.http.get(this.api.urls.reservations, {
      headers: {
        Authorization: `Bearer ${
          this.globals.currentUser.access_token
          }`
      },
      params: { page:page,paginate:'1' }

    });
  }
  allSessions = ():any => {
    return this.api.onlyFetch(this.api.urls.allSessions, {}, "get");
  }


  checkSession = (token, key, _token) => this.api.onlyFetch(this.api.urls.checkSession, { session_token: token, session_key_web: key }, "post", _token)
  startSession = (token, _token) => this.api.onlyFetch(this.api.urls.startSession, { session_token: token }, "post", _token)
  endSession = (token, notes, _token) => this.api.onlyFetch(this.api.urls.endSession, { session_token: token, notes }, "post", _token)
  reviewSession = (doctor_id, session_id, rating, title, comment, _token) => this.api.onlyFetch(this.api.urls.submitReview, { doctor_id, session_id, rating, title, comment }, "post", _token)
}
