import { Component, OnInit, OnDestroy } from '@angular/core';
import { CmsService } from '../../services/cms.service';

@Component({
	selector: 'app-all-articles',
	templateUrl: './all-articles.component.html',
	styleUrls: ['./all-articles.component.scss']
})
export class AllArticlesComponent implements OnInit, OnDestroy {
	articles = null
	scrollDetectVisible = false

	currentPage = 1
	lastPage = 1
	loadingMore = false
	totalCount = 0

	constructor(private cmsService: CmsService) { }

	ngOnInit() {
		this.loadArticles()

		window.onscroll = () => {
			let scrollPos = (<HTMLElement>document.querySelector('#scrollDetect')).offsetTop - window.scrollY - document.documentElement.clientHeight

			if (scrollPos < 0) {
				if (!this.scrollDetectVisible) {
					this.loadMore()
					this.scrollDetectVisible = true
				}
			}
			else this.scrollDetectVisible = false
		}
	}

	ngOnDestroy() {
		window.onscroll = () => {}
	}

	loadArticles = () => {
		this.articles = null
		this.cmsService.allArticles().subscribe((res: any) => {
			this.articles = res.data
			this.lastPage = res.meta.last_page
			this.totalCount = res.meta.total
		})
	}

	loadMore = () => {
		if (this.lastPage > this.currentPage) {
			this.loadingMore = true
			this.currentPage++
			this.cmsService.allArticlesMore(this.currentPage).subscribe((res: any) => {
				res.data.forEach(article => this.articles.push(article))
				this.lastPage = res.meta.last_page
				this.totalCount = res.meta.total
				this.loadingMore = false
			},
			() => this.loadingMore = false)
		}
	}

	share = (type, article) => {
		let link = `https://psycounselor.com/#/article/${article.id}`
		if (type == 'facebook')
			window.open(`http://www.facebook.com/sharer.php?u=${link}`)
		else if (type == 'twitter')
			window.open(`https://twitter.com/share?url=${link}&text=${article.title}&hashtags=psycounselor`)
		else if (type == 'googleplus')
			window.open(`https://plus.google.com/share?url=${link}`)
		else if (type == 'mail')
			document.location.href = `mailto:?Subject=${article.title}&Body=${link}%0D%0A%0D%0Ahttp://psycounselor.com`
	}

	subDescription = (desc: String) => desc.replace(/\r?\n/g, ' ').replace(/(<([^>]+)>)/ig, ' ').replace(/&nbsp;/g, ' ').substr(0, 160) + '...'

}
