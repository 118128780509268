import {
	Component,
	OnInit,
	Inject,
	ViewChild,
	ElementRef,
	Renderer2,
	AfterContentInit
} from "@angular/core";
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { PaymentService } from "../../services/payment.service";
import { Router, ActivatedRoute } from "@angular/router";
import { DOCUMENT, Title } from "@angular/platform-browser";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { Globals } from "../../services/Globals";
import { TranslateService } from "@ngx-translate/core";
import { Events } from "ionic-angular";
import { ApiService } from "../../services/api.service";
declare var require: any;

@Component({
	selector: "app-user-payment",
	templateUrl: "./user-payment.component.html",
	styleUrls: ["./user-payment.component.scss"]
})
export class UserPaymentComponent implements OnInit, AfterContentInit {
	paymentModalRef: BsModalRef
	paymentHyperpayModalRef: BsModalRef

	@ViewChild("paymentMessage") paymentMessage: ElementRef;
	@ViewChild("contentHyperpay") contentHyperpay;

	closeResult: string
	rechargeAmount = 1
	currentBalance = null
	transactions = []
	paymentResult = null
	isSuccessfull = false
	giftCard = ''

	intl

	paymentType = 'Paymob'

	hyperpayId = ''

	paymentAmounts = []

	constructor(
		@Inject(DOCUMENT) private document: any,
		private modalService: BsModalService,
		private paymentService: PaymentService,
		private router: Router,
		private route: ActivatedRoute,
		private renderer: Renderer2,
		private titleService: Title,
		public globals: Globals,
		private spinnerService: Ng4LoadingSpinnerService,
		private translate: TranslateService,
		private events: Events,
		public api: ApiService
	) {
		if (typeof Intl == 'undefined' || !Intl)
			this.intl = require('intl')

		translate.get('titles.myBalance').subscribe(value => titleService.setTitle(value))

		route.queryParams.subscribe(() => {
			if (this.paymentType == "Hyperpay" && this.hyperpayId != '') {
				this.paymentHyperpayModalRef.hide()

				history.pushState(null, "", location.href.split("?")[0])

				this.globals.showSpinner()
				this.paymentService.rechargeHyperpayConfirm(this.hyperpayId).subscribe(
					(res: any) => {
						let code = res.server_response.result.code
						if (/^(000\.400\.0[^3]|000\.400\.100)/.test(code) || /^(000\.000\.|000\.100\.1|000\.[36])/.test(code)) {
							this.globals.hideSpinner()
							this.globals.showToast(this.translate.instant('balance.paymentSuccess'), 'success')
							setTimeout(() => {
								window.location.reload()
							}, 500)
						}
						else {
							this.globals.hideSpinner()
							this.globals.showToast(this.translate.instant('balance.paymentFailed'), 'error')
							setTimeout(() => {
								window.location.reload()
							}, 500)
						}
						this.hyperpayId = ''
					},
					() => {
						this.hyperpayId = ''
						this.globals.hideSpinner()
						this.globals.showToast(this.translate.instant('balance.paymentFailed'), 'error')
						setTimeout(() => {
							window.location.reload()
						}, 500)
					}
				);
			}
		})

		api.onlyFetch('https://psycounselor.com:49753/api/payment/get_amounts', {}, 'get').subscribe((res: any) => {
			this.paymentAmounts = res
		})
	}

	ngOnInit() {
		this.getHistory()
	}

	ngAfterContentInit() {
		if (this.route.snapshot.queryParams["type"] == "paypal") {
			const state = this.route.snapshot.queryParams["state"]

			let data = {
				paymentToken: this.route.snapshot.queryParams["token"],
				PayerID: this.route.snapshot.queryParams["PayerID"],
				paymentId: this.route.snapshot.queryParams["paymentId"],
			}

			history.pushState(null, "", location.href.split("?")[0])

			if (state === "success") {
				this.globals.showSpinner()

				this.paymentService.captureSuccessPayment(data).subscribe((res: any) => {
					if (res.status == "SUCCESS") {
						this.globals.currentUser.user.credit = res.user_balance
						this.currentBalance = res.user_balance
						this.transactions = [res.last_transaction, ...this.transactions]

						this.globals.showToast(this.translate.instant('yourBalance'), 'success')
					}
					this.globals.hideSpinner()
				})
			}
			else if (state === "failed") {
				// this.paymentService.captureFailedPayment(data).subscribe((res: any) => {
				// 	if (!res.message)
				// 		this.globals.showToast(this.translate.instant('rechargingFailed'), 'error')
				// })
				this.globals.showToast(this.translate.instant('rechargingFailed'), 'error')
			}
		}
		else {
			if (this.route.snapshot.queryParams["captured_amount"] != null && this.route.snapshot.queryParams["captured_amount"] !== undefined) {
				this.globals.showSpinner()
				this.paymentService.rechargePaymobConfirm(location.href).subscribe(
					(res: any) => {
						this.globals.currentUser.user.credit = res.balance
						this.currentBalance = res.balance
						this.transactions = [res.data, ...this.transactions]

						history.pushState(null, "", location.href.split("?")[0])

						if (res.success) {
							this.globals.showToast(this.translate.instant('yourBalance'), 'success')
						}
						else {
							this.globals.showToast(this.translate.instant('rechargingFailed'), 'error')
						}

						this.globals.hideSpinner()
					},
					() => {
						history.pushState(null, "", location.href.split("?")[0])
						this.globals.hideSpinner()
						this.globals.showToast(this.translate.instant('rechargingFailed'), 'error')
					}
				);
			}
		}
	}

	open = (content) => this.paymentModalRef = this.modalService.show(content, { class: "theme-modal fast-modal" })

	getHistory = (show = false) => {
		if (show)
			this.globals.showSpinner()
		this.paymentService.history().subscribe((res: any) => {
			this.transactions = res.data
			this.currentBalance = res.user_balance
			if (show)
				this.globals.hideSpinner()
		})
	}

	recharge(amount = null) {
		this.globals.showSpinner()
		if (this.paymentType == 'Paypal') {
			this.paymentService.recharge(amount ? amount : this.rechargeAmount).subscribe(
				(res: any) => {
					this.document.location.href = res.data.redirect_url
				},
				() => {
					this.globals.hideSpinner()
					this.globals.showToast(this.translate.instant('somethingWrong'), 'error')
				}
			);
		}
		else if (this.paymentType == "Hyperpay") {
			this.paymentService.rechargeHyperpay(amount ? amount : this.rechargeAmount).subscribe(
				(res: any) => {
					this.hyperpayId = res.server_response.id

					let s = document.querySelector("#hyperpayscript")
					if (s) s.remove()

					var script = document.createElement('script')
					script.id = 'hyperpayscript'
					script.type = 'text/javascript'
					script.src = `https://oppwa.com/v1/paymentWidgets.js?checkoutId=${this.hyperpayId}`
					document.getElementsByTagName('body')[0].appendChild(script)

					this.paymentModalRef.hide()
					this.paymentHyperpayModalRef = this.modalService.show(this.contentHyperpay, { class: "theme-modal fast-modal", backdrop: 'static', keyboard: false })

					setTimeout(() => {
						this.globals.hideSpinner()
					}, 5000)
				},
				() => {
					this.globals.hideSpinner()
					this.globals.showToast(this.translate.instant('somethingWrong'), 'error')
				}
			);
		}
		else if (this.paymentType == "Paymob") {
			this.paymentService.recharge(amount ? amount : this.rechargeAmount).subscribe(
				(res: any) => {
					this.document.location.href = res.data
				},
				() => {
					this.globals.hideSpinner()
					this.globals.showToast(this.translate.instant('somethingWrong'), 'error')
				}
			);
		}
	}

	cancelPayment = () => {
		this.paymentHyperpayModalRef.hide()

		this.globals.showSpinner()
		this.paymentService.rechargeHyperpayCancel().subscribe(
			() => {
				this.globals.hideSpinner()
				this.globals.showToast(this.translate.instant('balance.paymentCanceled'), 'success')
				setTimeout(() => {
					window.location.reload()
				}, 500)
			},
			() => {
				this.globals.hideSpinner()
				this.globals.showToast(this.translate.instant('somethingWrong'), 'error')
				setTimeout(() => {
					window.location.reload()
				}, 500)
			}
		);
	}

	useGiftCard = () => {
		this.globals.showSpinner()
		this.paymentService.useGiftCard(this.giftCard).subscribe(
			() => {
				this.globals.showToast(this.translate.instant('balance.validGiftCard'), 'success')
				setTimeout(() => this.document.location.reload(), 1000)
			},
			() => {
				this.globals.hideSpinner()
				this.globals.showToast(this.translate.instant('balance.invalidGiftCard'), 'error')
			}
		);
	}

	formatNumber = (number) => {
		number = parseFloat(number)

		if (typeof Intl == 'undefined' || !Intl)
			return new this.intl.NumberFormat().format(number)
		else return new Intl.NumberFormat().format(number)
	}

	private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-']
	handleNumberEvent = (ev) => {
		if (this.specialKeys.indexOf(ev.key) !== -1) return

		let current: string = ev.target.value
		let next: string = current.concat(ev.key)
		if (next && !String(next).match(/^-?[0-9]+(\.[0-9]*){0,1}$/g))
			ev.preventDefault()
	}

	validValue = () => /^\d+$/.test(this.rechargeAmount.toString()) && this.rechargeAmount > 0 && this.rechargeAmount <= 10000

	completeProfile = () => {
		this.paymentModalRef.hide()
		this.events.publish('show-update-modal')
	}

	checkPayment = () => this.globals.currentUser.user.profile ? (
		this.globals.currentUser.user.profile.country_id > -1 &&
		this.globals.currentUser.user.profile.city_id > -1 &&
		this.globals.currentUser.user.profile.street1 != "" && this.globals.currentUser.user.profile.street1 != null &&
		this.globals.currentUser.user.profile.apartment != "" && this.globals.currentUser.user.profile.apartment != null &&
		this.globals.currentUser.user.profile.building != "" && this.globals.currentUser.user.profile.building != null &&
		this.globals.currentUser.user.profile.floor != "" && this.globals.currentUser.user.profile.floor != null) : false
}
