import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "dv-selector",
  templateUrl: "./dv-selector.component.html",
  styleUrls: ["./dv-selector.component.scss"]
})
export class DvSelectorComponent implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input("items") items;
  @Output("selectedLanguages") selectedLanguages: EventEmitter<any> = new EventEmitter();

  isOpen = false;

  selectedItems = [];

  constructor() {}

  ngOnInit() {
    
  }

  addItem(lang) {
    if(!this.selectedItems.find((selected) => lang.id == selected.id)) {
      this.selectedItems.push(lang);
      this.isOpen = false;
      this.selectedLanguages.emit(this.selectedItems);
    }
  }

  removeItem(i) {
    this.selectedItems.splice(i, 1);
    this.selectedLanguages.emit(this.selectedItems);
  }

  @Input("selectedLangs")
  set selectedLangs(selectedLangs) {
    this.selectedItems = selectedLangs
  }
}
