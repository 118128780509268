import { Component, OnInit, ViewChild } from "@angular/core";
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { UserService } from "../../services/user.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { Title } from "@angular/platform-browser";
import { Globals } from "../../services/Globals";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "../../services/api.service";
import { TranslateService } from "@ngx-translate/core";
declare var moment: any;

@Component({
	selector: "user-account",
	templateUrl: "./user-account.component.html",
	styleUrls: ["./user-account.component.scss"]
})
export class UserAccountComponent implements OnInit {

	currentPage = 1
	lastPage = 1
	loading = true
	loadingMore = false
	scrollDetectVisible = false
	body = { paginate: '1' }
	totalCount = 0
	noResult = false

	@ViewChild('instructionsModal') instructionsModal

	detailsModalRef: BsModalRef
	instructionsModalRef: BsModalRef

	tab = 1
	currentReservation
	reservations = null
	reservationsFiltered = null
	reservationsUsers = []

	filterUser = 0
	filterStatus = "-1"
	filterSort = "0"

	displayPicker = false

	allSessions = null

	instructionsDontShow = false
	currentToken = null

	constructor(
		private modalService: BsModalService,
		private userService: UserService,
		private spinnerService: Ng4LoadingSpinnerService,
		private titleService: Title,
		public globals: Globals,
		private router: Router,
		private apiService: ApiService,
		private translate: TranslateService,
		public route: ActivatedRoute

	) {
		translate.get('titles.myAccount').subscribe(value => titleService.setTitle(value))

		route.params.subscribe(params => this.tab = params['_tab'] == 'reservations' ? 2 : 1)
	}

	openlg(content, reservaion) {
		this.currentReservation = reservaion
		this.detailsModalRef = this.modalService.show(content, { class: "fast-modal book-now-modal theme-modal modal-lg", animated: true })
	}

	ngOnInit() {
		this.refreshReservations()
		this.refreshUpcomingSessions()
		window.onscroll = () => {
			let element = (<HTMLElement>document.querySelector('#scrollDetect'))
			if (element != null) {
				let scrollPos = (element.offsetTop + 129) - window.scrollY - document.documentElement.clientHeight

				if (scrollPos < 0 || element.offsetTop < document.documentElement.clientHeight) {
					if (!this.scrollDetectVisible) {
						this.loadMore()
						this.scrollDetectVisible = true
					}
				}
				else this.scrollDetectVisible = false
			}
		}
	}

	loadMore = () => {
		if (this.lastPage > this.currentPage) {
			this.loadingMore = true
			this.currentPage++
			this.userService.loadMore(this.currentPage).subscribe((res: any) => {
				res.data.forEach(session => this.reservationsFiltered.push(session))
				this.lastPage = res.meta.last_page
				this.loadingMore = false
			},
				() => this.loadingMore = false)
		}
	}

	filterReservations = (event) => {
		this.body = {
			paginate: '1',
		}
		if (this.filterStatus !== '-1') {
			this.body['status'] = this.filterStatus
		}
		if (this.filterUser !== 0) {
			this.body['user_id'] = this.filterUser.toString()
		}
		this.body['sort'] = this.filterSort
		this.refreshReservations()


		// this.reservationsFiltered = this.reservations.filter(res => {
		// 	let isUser = this.filterUser == 0
		// 	if (this.apiService.role == 'doctor')
		// 		isUser = this.filterUser == 0 ? true : res.user.id == this.filterUser
		// 	else
		// 		isUser = this.filterUser == 0 ? true : res.doctor_details ? res.doctor_details.doctor.id == this.filterUser : false

		// 	return isUser && (this.filterStatus == "-1" ? true : (!!parseInt(this.filterStatus) ? this.checkIfReservationEnded(res) : !this.checkIfReservationEnded(res)))
		// })

		// if (this.filterSort == "1")
		// 	this.reservationsFiltered = this.reservationsFiltered.sort((a, b) => moment(a.created_at).diff(b.created_at))
		// else this.reservationsFiltered = this.reservationsFiltered.sort((a, b) => moment(b.created_at).diff(a.created_at))
	}


	refreshReservations = () => {
		this.reservations = null
		this.reservationsFiltered = null
		this.loading = true

		this.userService.reservations(this.body).subscribe((res: any) => {
			console.log('resss', res);
			this.totalCount = res.meta.total
			this.lastPage = res.meta.last_page
			this.reservations = res.data.sort((a, b) => moment(b.created_at).diff(a.created_at))
			this.reservationsFiltered = res.data.sort((a, b) => moment(b.created_at).diff(a.created_at))
			console.log('res.data', res.data);
			if (this.reservations.length === 0) {
				this.noResult = true
			} else {
				this.noResult = false
			}
			if (this.apiService.role == 'doctor') {
				let allUsers: any = res.data.map(res => res.user)
				res.data.forEach(element => {
					// console.log(this.reservationsUsers.some(e => e.id == element.user!=null ? element.user.id : -1));
					if (element.user)
						if (!this.reservationsUsers.some(e => e.id == element.user.id))
							this.reservationsUsers.push({ id: element.user.id, name: element.user.name })
				})
			}
			else
				res.data.forEach(element => {

					if (!this.reservationsUsers.some(e => e.id == (element.doctor_details && element.doctor_details.doctor) ? element.doctor_details.doctor.id : -1))
						this.reservationsUsers.push({ id: element.doctor_details.doctor.id, name: element.doctor_details.doctor.name })
				})
		})
	}

	refreshUpcomingSessions = () => {
		this.allSessions = null
		this.userService.allSessions().subscribe((res: any) => {
			this.allSessions = res
		})
	}
	openSessionCheck = (token) => {
		this.currentToken = token
		if (this.globals.secureStorage.getItem('instructionsDontShow') != null)
			this.openSession(token)
		else
			this.instructionsModalRef = this.modalService.show(this.instructionsModal, { class: "fast-modal book-now-modal theme-modal modal-lg", animated: true })
	}

	openSession = (token) => {
		if (this.instructionsDontShow)
			this.globals.secureStorage.setItem('instructionsDontShow', true)

		if (this.detailsModalRef && this.detailsModalRef != undefined)
			this.detailsModalRef.hide()

		if (this.globals.currentUser.user.role.name == 'doctor') {
			this.router.navigate(['/stream', token])
		}
		else {
			this.globals.showSpinner()

			let key = this.globals.secureStorage.getItem('currentSessionKey')
			if (key == null) {
				this.globals.secureStorage.setItem('currentSessionKey', Math.random().toString(36).replace(/[^a-z]+/g, ''))
				key = this.globals.secureStorage.getItem('currentSessionKey')
			}

			this.userService.checkSession(token, key, null).subscribe((res: any) => {
				this.globals.hideSpinner()

				if (res.type == 'success') {
					if (res.data.is_started == 1 && !res.data.end_date)
						this.router.navigate(['/stream', token])
					else this.globals.showToast(this.translate.instant('doctorIsNotReady'), 'info')
				}
				else {
					if (res.message == 402)
						this.globals.showToast(this.translate.instant('alreadyInSession'), 'info')
					else this.globals.showToast(this.translate.instant('doctorIsNotReady'), 'info')
				}
			})
		}
	}

	checkIfTime = (time, ended = false) => {
		let now = moment(new Date())
		let end = moment(time)
		let diff = moment.duration(now.diff(end)).asMinutes()

		if (ended)
			return diff > 55
		return diff > -5 && diff < 55
	}

	checkIfReservationEnded = (reservation) => reservation.sessions.filter(session => session.doctor_details ? moment() < moment(session.doctor_details.timestamp).add({ hour: 1 }) : false).length > 0 ? false : true



	getRangeDateString = () => ""

	translateTitle = (title) => {
		let translated = title
		if (this.translate.getDefaultLang() == "ar") {
			if (title.toLowerCase() == 'today') translated = 'اليوم'
			if (title.toLowerCase() == 'this week') translated = 'هذا الاسبوع'
			if (title.toLowerCase() == 'this year') translated = 'هذه السنة'
		}
		return translated
	}

	parseTime = (time) => {
		let hour = time.split(':')[0]
		let minutes = time.split(':')[1]
		let ampm = 'AM'

		if (parseInt(hour) > 12) {
			ampm = 'PM'
			hour = parseInt(hour) - 12
		}

		return `${hour}:${minutes} ${ampm}`
	}
}
