import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';


import { CoShowcaseComponent, CoShowcaseItemComponent } from './components/co-showcase/co-showcase.component';
import { CoTrackerDirective } from './directives/tracker/co-tracker.directive';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
  ],
  declarations: [
    CoTrackerDirective,
    CoShowcaseComponent,
    CoShowcaseItemComponent,
  ],
  exports: [
    CoTrackerDirective,
    CoShowcaseComponent,
    CoShowcaseItemComponent
  ]
})

export class CodeativeModule { }
