import { Component, OnInit } from '@angular/core';
// import { isValidNumber } from 'libphonenumber-js'
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { Globals } from "../../services/Globals";
import { AuthService } from "../../services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { Router } from '@angular/router';
declare var AOS: any;
declare var libphonenumber: any;

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
	step = 1
	verifyType = ''

	email = ''
	phone = ''
	code = ''
	phoneCode = ''
	password = ''

	resendActivationCode = false
	resendActivationCodeInterval
	resendActivationCodeTime = 60

	passwordToggle = true

	countries = []
	
	constructor(
			public spinnerService: Ng4LoadingSpinnerService,
			public globals: Globals,
			public authService: AuthService,
			public translate: TranslateService,
			private router: Router) { }

	ngOnInit() {
		AOS.init()

		this.globals.showSpinner()
		this.authService.before_register().subscribe((res: any) => {
			this.countries = res.countries
			this.globals.hideSpinner()
		})
	}

	sendVerificationCode = () => {
		this.globals.showSpinner()
		this.authService.forgotPassword({
			through: this.verifyType,
			to: this.verifyType == 'email' ? this.email : this.phone
		}).subscribe((res: any) => {
			if (res.type != 'success')
				this.globals.showToast(this.translate.instant('somethingWrong'), 'error')
			else {
				this.resendActivationCodeTime = 60
				this.resendActivationCode = false
				this.step = 3
				this.globals.showToast(this.translate.instant('checkTheVerification'), 'success')
			}

			this.globals.hideSpinner()
		},
		() => {
			this.globals.showToast(this.translate.instant('somethingWrong'), 'error')
			this.globals.hideSpinner()
		})
	}

	verify = () => {
		this.globals.showSpinner()
		this.authService.forgotPasswordActivate({
			code: this.code,
			password: this.password
		}).subscribe((res: any) => {
			if (res.type != 'success')
				this.globals.showToast(this.translate.instant('somethingWrong'), 'error')
			else {
				this.router.navigate(['/login'])
				this.globals.showToast(this.translate.instant('yourPassword'), 'success')
			}
			
			this.globals.hideSpinner()
		},
		() => {
			this.globals.showToast(this.translate.instant('somethingWrong'), 'error')
			this.globals.hideSpinner()
		})
	}

	resetForm = () => {
		this.step = 1
		this.verifyType = ''
		this.email = ''
		this.phone = ''
		this.phoneCode = ''
		this.code = ''
	}

	validateEmail = () => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(this.email).toLowerCase())
	// validatePhone = () => isValidNumber(`+${this.phoneCode}${this.phone}`)
	validatePhone = () => {
		if (this.countries.length > 0)
			if (!this.phone.startsWith('0') && /^\d+$/.test(this.phone))
				return libphonenumber.isValidNumber(`+${this.countries.filter(country => country.id == this.phoneCode)[0].phonecode}${this.phone}`)
			else return false
		return false
	}

	togglePassword = () => this.passwordToggle = !this.passwordToggle

}
