import { Component, Output, EventEmitter, transition, Input, AfterViewInit } from '@angular/core';

@Component({
  selector: 'psyco-disease-details',
  templateUrl: './disease-details.component.html',
  styleUrls: ['./disease-details.component.scss']
})
export class DiseaseDetailsComponent implements AfterViewInit {

  @Output('closed') closed: EventEmitter<PointerEvent> = new EventEmitter();


  @Input('view') view: any;
  @Input('video') video: any;

  constructor() { }

  ngAfterViewInit() {}

  close (ev) {
    this.closed.emit(ev);
  }

  checkArabic = (text) => /[\u0600-\u06FF]/.test(text)

}
