import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "./api.service";

@Injectable()
export class PaymentService {
  constructor(private api: ApiService) {}

  recharge = amount => this.api.onlyFetch(this.api.urls.paymentPaymob, { amount })
  rechargeHyperpay = amount => this.api.onlyFetch(this.api.urls.paymentHyper, { amount })

  rechargeHyperpayConfirm = resourcePath => this.api.onlyFetch(this.api.urls.paymentHyperConfirm, { resourcePath })
  rechargePaymobConfirm = query => this.api.onlyFetch(this.api.urls.paymentPaymobConfirm, { query })

  rechargePaymobConfirmApp = (query, order_id) => this.api.anyFetch(this.api.urls.paymentAppComplete, { query, order_id })

  rechargeHyperpayCancel = () => this.api.onlyFetch(this.api.urls.paymentHyperCancel, {})

  history = () => this.api.onlyFetch(this.api.urls.paymentHistory, {}, "get")

  check = () => this.api.onlyFetch(this.api.urls.checkPayment, {}, "get")

  useGiftCard = (code) => this.api.onlyFetch(this.api.urls.useGiftCard, { code })

  captureSuccessPayment = (data, token = null) => this.api.onlyFetch(this.api.urls.paypal_success, data, "get", token)
	captureFailedPayment = (data, token = null) => this.api.onlyFetch(this.api.urls.paypal_cancel, data, "get", token)
}
