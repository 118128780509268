import { Injectable } from "@angular/core";
import {
	CanActivate,
	Router,
	ActivatedRouteSnapshot,
	RouterStateSnapshot
} from "@angular/router";
import { Globals } from "./Globals";

@Injectable()
export class AuthGuardNotUser implements CanActivate {
	constructor(private globals: Globals, private router: Router) { }

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean {
		const url: string = state.url;

		return this.checkLogin(url);
	}

	checkLogin(url: string): boolean {
		if (this.globals.currentUser == null) {
			return true;
		}

		this.router.navigate(["/home"]);
		return false;
	}
}