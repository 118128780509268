import { Component, OnInit } from '@angular/core';
import { CmsService } from '../../services/cms.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MetaService } from '@ngx-meta/core';
declare var moment: any;

@Component({
	selector: 'app-article',
	templateUrl: './article.component.html',
	styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {
	articleId = "0"
	article = null
	articles = null

	constructor(private cmsService: CmsService,
			private route: ActivatedRoute,
			private router: Router,
			private meta: MetaService) {
		route.params.subscribe(params => {
			this.articleId = params['id']
			if (!/^\d+$/.test(this.articleId))
				this.router.navigate(['\articles'])

			this.loadArticle(this.articleId)
		})
	}

	ngOnInit() {
		this.articleId = this.route.snapshot.paramMap.get('id')
		if (!/^\d+$/.test(this.articleId))
			this.router.navigate(['\articles'])

		this.loadArticle(this.articleId)
	}

	loadArticle = (id) => {
		this.cmsService.article(id).subscribe((res: any) => {
			if (res.message && res.message == "Entity Not found")
				this.router.navigate(['\home'])

			this.article = res.data
			// this.meta.setTitle(this.article.title)
			// this.meta.setTag('og:description', this.subDescription(this.article.body))
			// this.meta.setTag('og:image', this.article.image)
			// this.meta.setTag('og:url', `https://psycounselor.com/#/article/${this.article.id}`)
		}, () => this.router.navigate(['\articles']))

		this.cmsService.allArticles().subscribe((res: any) => {
			this.articles = res.data.filter(article => article.id != parseInt(this.articleId)).slice(0, 3)
		})
	}

	share = (type) => {
		let link = `https://psycounselor.com/#/article/${this.article.id}`
		if (type == 'facebook')
			window.open(`http://www.facebook.com/sharer.php?u=${link}`)
		else if (type == 'twitter')
			window.open(`https://twitter.com/share?url=${link}&text=${this.article.title}&hashtags=psycounselor`)
		else if (type == 'googleplus')
			window.open(`https://plus.google.com/share?url=${link}`)
		else if (type == 'mail')
			document.location.href = `mailto:?Subject=${this.article.title}&Body=${link}%0D%0A%0D%0Ahttp://psycounselor.com`
	}

	formatDate = (date) => moment(date).format('LLLL')
	subDescription = (desc: String) => desc.replace(/\r?\n/g, ' ').replace(/(<([^>]+)>)/ig, ' ').replace(/&nbsp;/g, ' ').substr(0, 80) + '...'

}