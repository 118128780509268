import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ModalModule } from 'ngx-bootstrap';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { CodeativeModule } from "./modules/codeative/core.module";

import { LoginComponent } from "./pages/login/login.component";
import { SignupComponent } from "./pages/signup/signup.component";
import { DoctorSignupComponent } from "./pages/doctor-signup/doctor-signup.component";
import { CategoriesComponent } from "./pages/categories/categories.component";
import { DoctorProfileComponent } from "./pages/doctor-profile/doctor-profile.component";
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component";
import { StreamComponent } from "./pages/stream/stream.component";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { FooterComponent } from "./components/footer/footer.component";
import { ResendCodeComponent } from "./pages/resend-code/resend-code.component";
import { AfterPaymentComponent } from './pages/after-payment/after-payment.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';

import { Globals } from "./services/Globals";
import { DoctorsService } from "./services/doctors.service";
import { UserService } from "./services/user.service";
import { FilterService } from "./services/filter.service";
import { AuthGuard } from "./services/AuthGuard";
import { AuthGuardUser } from "./services/AuthGuardUser";
import { AuthGuardNotUser } from "./services/AuthGuardNotUser";
import { AuthGuardDoctor } from "./services/AuthGuardDoctor";
import { AuthService } from "./services/auth.service";
import { ApiService } from "./services/api.service";
import { EditDoctorProfileComponent } from "./pages/edit-doctor-profile/edit-doctor-profile.component";
import { DvSelectorComponent } from "./components/dv-selector/dv-selector.component";
import { Base64codePipe } from "./pipes/base64code.pipe";
import { UserAccountComponent } from "./pages/user-account/user-account.component";
import { UserPaymentComponent } from "./pages/user-payment/user-payment.component";
import { PaymentService } from "./services/payment.service";
import { StatesService } from "./services/states.service";
import { MonthToStringPipe } from "./pipes/month-to-string.pipe";
import { FakerService } from "./services/faker.service";
import { NgbdDatepickerRange } from "./components/datepicker-range/datepicker-range.component";
import { AboutUsComponent } from "./pages/about-us/about-us.component";
import { ContactUsComponent } from "./pages/contact-us/contact-us.component";
import { LandingComponent } from "./pages/landing/landing.component";
import { CmsService } from "./services/cms.service";
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { ErrorInterceptor } from "./services/ErrorInterceptor";
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule, ToastNoAnimation, ToastNoAnimationModule } from 'ngx-toastr';
import { StarRatingModule } from 'angular-star-rating'
import { NgxEditorModule } from 'ngx-editor';
import { TooltipModule } from 'ngx-bootstrap';
import { TabsModule, TabsetConfig } from 'ngx-bootstrap';
import { TextareaAutosizeModule } from 'ngx-textarea-autosize';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
import { DiseaseDetailsComponent } from './components/disease-details/disease-details.component';
import { SafePipe } from './pipes/safe.pipe';
import { Ng5SliderModule } from 'ng5-slider';
import { AllArticlesComponent } from './pages/all-articles/all-articles.component';
import { ArticleComponent } from './pages/article/article.component';
import { Events } from "ionic-angular";
import { MetaModule } from '@ngx-meta/core';

@NgModule({
	declarations: [
		AppComponent,
		NavbarComponent,
		FooterComponent,
		LoginComponent,
		SignupComponent,
		DoctorSignupComponent,
		CategoriesComponent,
		DoctorProfileComponent,
		PageNotFoundComponent,
		EditDoctorProfileComponent,
		AboutUsComponent,
		ContactUsComponent,
		LandingComponent,
		StreamComponent,
		DvSelectorComponent,
		UserAccountComponent,
		UserPaymentComponent,
		Base64codePipe,
		MonthToStringPipe,
		NgbdDatepickerRange,
		DiseaseDetailsComponent,
		SafePipe,
		AfterPaymentComponent,
		ResendCodeComponent,
		ForgotPasswordComponent,
		AllArticlesComponent,
		ArticleComponent
	],
	imports: [
		AppRoutingModule,
		NgbModule.forRoot(),
		ModalModule.forRoot(),
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		Ng4LoadingSpinnerModule.forRoot(),
    	CodeativeModule,
		ToastNoAnimationModule,
		ToastrModule.forRoot({
			toastComponent: ToastNoAnimation,
			preventDuplicates: true
		}),
		TranslateModule.forRoot({
			loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
		}),
		StarRatingModule.forRoot(),
		NgxEditorModule,
		TooltipModule.forRoot(),
		TextareaAutosizeModule,
		Ng5SliderModule,
		MetaModule.forRoot(),
		TabsModule
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		AuthService,
		ApiService,
		CmsService,
		DoctorsService,
		UserService,
		Globals,
		FilterService,
		AuthGuard,
		AuthGuardUser,
		AuthGuardNotUser,
		AuthGuardDoctor,
		PaymentService,
		StatesService,
		FakerService,
		Events,
		TabsetConfig
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
