import {
	Component,
	OnInit,
	Renderer2,
	ViewChild
} from "@angular/core";
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { DoctorsService } from "../../services/doctors.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Globals } from "../../services/Globals";
import { Title } from "@angular/platform-browser";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { TranslateService } from "@ngx-translate/core";
import { PaymentService } from "../../services/payment.service";
import { UserService } from "../../services/user.service";
declare var require: any;

@Component({
	selector: "app-doctor-profile",
	templateUrl: "./doctor-profile.component.html",
	styleUrls: ["./doctor-profile.component.scss"]
})
export class DoctorProfileComponent implements OnInit {
	reserveModalRef: BsModalRef
	reserveFreeModalRef: BsModalRef

	currentTab = 1
	step = 0
	selectedPackage
	@ViewChild("bookNowModal") bookNowModal

	doctorData = null
	doctorReviews
	reservation
	months = []
	allSessions = []
	selectedMonth = null
	availableDates = []
	availableTimes = []
	showTimes = false

	selectedDay = null
	selectedDays = []
	sessions_days = []
	sessions_count
	userCredit = 0

	intl

	giftCard = ''

	paymentMethod = 1

	constructor(
		private modalService: BsModalService,
		private DoctorsApi: DoctorsService,
		private route: ActivatedRoute,
		private router: Router,
		private renderer: Renderer2,
		public global: Globals,
		private titleService: Title,
		private userService: UserService,
		private globals: Globals,
		private spinnerService: Ng4LoadingSpinnerService,
		private translate: TranslateService,
		private paymentService: PaymentService
	) {
		if (typeof Intl == 'undefined' || !Intl)
			this.intl = require('intl')

		translate.get('titles.doctorProfile').subscribe(value => titleService.setTitle(value))

		route.params.subscribe(params => {
			const doctorId = params['id']
			if (!/^\d+$/.test(doctorId))
				this.router.navigate(['\home'])

			this.doctorData = null
			const userId = this.global.currentUser ? this.global.currentUser.user.id : null

			this.loadDoctorData(doctorId, userId)
		})
	}

	ngOnInit() {
		console.log('is arabic?',this.globals.isArabic('سيسي سيسي سيص ثصثصث صث '));
		const doctorId = this.route.snapshot.paramMap.get("id")
		if (!/^\d+$/.test(doctorId))
			this.router.navigate(['\home'])

		const userId = this.global.currentUser ? this.global.currentUser.user.id : null

		this.loadDoctorData(doctorId, userId)

		if (this.route.snapshot.paramMap.get("book"))
			this.openBookNowModal(this.bookNowModal)
	}

	loadDoctorData = (doctorId, userId) => {
		this.DoctorsApi.one(doctorId, userId).subscribe((res: any) => {
			if (res.message && res.message == "Entity Not found")
				this.router.navigate(['\home'])

			this.doctorData = res.data;
			console.log('this.globals.currentUser',this.globals.currentUser);
			console.log('this.doctorData',this.doctorData);
			this.allSessions = null
			this.userService.allSessions().subscribe((res: any) => {
				this.allSessions = res
				console.log('this.allSessions',this.allSessions);

			})
			if(this.doctorData) {
				setTimeout(() => {
					let about = document.getElementById('testText')
					if(this.globals.isArabic(res.data.doctor.about)){
						about.style.fontFamily = 'cairo'
					}else {
						about.style.fontFamily = 'Montserrat'
					}
					about.innerHTML = res.data.doctor.about
				}, 50);

			}

			if (this.globals.currentUser)
				this.userCredit = res.data.user_credit
			this.titleService.setTitle(`${this.translate.instant('titles.psycho')}${res.data.name}${this.translate.instant('titles.sprofile')}`)
		}, () => this.router.navigate(['\home']))

		this.DoctorsApi.getReviews(doctorId).subscribe((res: any) => {
			this.doctorReviews = res.data;
		})
	}

	arrayOne(n: number): any[] {
		return Array(n);
	}

	getLanguages = () => {
		if (this.doctorData)
			return this.doctorData.languages.map(lang => lang.name).join(' / ')
		return ''
	}

	openFreeModal(content) {
		console.log('dsdsdhhhdh');

		if (this.global.currentUser) {
			this.globals.showSpinner()
			this.DoctorsApi.getFreeReservations(this.doctorData.id).subscribe((res: any) => {
				if (res.message){
					console.log(res.message);
					if(this.globals.currantLanguage == 'ar') {
						this.globals.showToast('لا يوجد جلسات متاحة لهذا الطبيب', 'error')
					} else {
						this.globals.showToast(res.message, 'error')
					}

				}
				else {
					this.months = Object.keys(res)
					this.selectedMonth = this.months[new Date().toLocaleString("en-us", { month: "long" })] ? new Date().toLocaleString("en-us", { month: "long" }) : this.months[0]
					this.availableDates = res
					this.getMonthDays()

					this.reserveFreeModalRef = this.modalService.show(content, { class: "theme-modal book-now-modal modal-lg", animated: true })
					this.selectedDays = []
					this.step = 0
				}

				this.globals.hideSpinner()
			},
			(error) => {
				this.globals.showToast(this.translate.instant('somethingWrong'), 'error')
				this.globals.hideSpinner()
			})
		}
		else this.router.navigate(['/login'])
	}

	openBookNowModal(content) {
		if (this.global.currentUser) {
			this.reserveModalRef = this.modalService.show(content, { class: "theme-modal book-now-modal modal-lg", animated: true })
			this.step = 0;
			this.selectedDays = []
		}
		else this.router.navigate(['/login'])
	}

	selecteDate(ev, day) {
		this.selectedDay = day
		this.availableTimes = this.availableDates[this.selectedMonth].filter(elem => elem.day == day.day).map(elem => elem.data)
	}

	selectedBefore = () => this.selectedDays.filter(day => day.day == this.selectedDay.day).length > 0

	addDay(day, time) {
		let dayDate = this.availableDates[this.selectedMonth].filter(availableDate => availableDate.day == day.day && availableDate.data.time == time.time && availableDate.data.time_plus_one == time.time_plus_one)[0]
		dayDate['month'] = this.selectedMonth
		dayDate['time'] = time.time
		dayDate['time_plus_one'] = time.time_plus_one

		if (this.selectedDays.length < this.sessions_count)
			this.selectedDays.push(dayDate)
	}

	addFreeDay(day, time) {
		day['month'] = this.selectedMonth
		day['time'] = time.time
		day['time_plus_one'] = time.time_plus_one
		if (this.selectedDays.length < 1)
			this.selectedDays.push(day)
	}

	removeDay = (idx) => this.selectedDays.splice(idx, 1)

	selectPackage(step, selectedPackage) {
		this.selectedPackage = selectedPackage
		this.sessions_count = this.selectedPackage.sessions_count
		const id = selectedPackage.id

		this.selectedDay = null
		this.selectedDays = []

		this.globals.showSpinner()
		this.DoctorsApi.getReservations(this.doctorData.id, id).subscribe((res: any) => {
			if (res.message) {
				if(this.globals.currantLanguage === 'ar'){
					this.globals.showToast('لا يوجد جلسات متاحة لهذا الطبيب الان', 'error')
				}else {
					this.globals.showToast(res.message, 'error')
				}
				// console.log('error messsage');

			}
			else {
				this.step = step
				this.months = Object.keys(res)
				this.selectedMonth = this.months[new Date().toLocaleString("en-us", { month: "long" })] ? new Date().toLocaleString("en-us", { month: "long" }) : this.months[0]
				this.availableDates = res
				this.getMonthDays()
			}

			this.globals.hideSpinner()
		},
		(error) => {
			// console.log('error',error)
			// if(error.error.message === "The given data was invalid."){
			// 	if(this.globals.currantLanguage === 'ar'){
			// 		this.globals.showToast('هذه الجلسة تم حجزها من شخص أخر قبلك مباشرة ', 'error')
			// 	} else {
			// 		this.globals.showToast('This session  has already been taken from some one soon', 'error')
			// 	}
			// } else {
			// 	this.globals.showToast(this.translate.instant('somethingWrong'), 'error')
			// }
			this.globals.showToast(this.translate.instant('somethingWrong'), 'error')
			this.globals.hideSpinner()
		})
	}

	reserve = () => {
		const reservedDaysIds = this.selectedDays.map(date => {
			return date.data.id
		})

		const reserveData = {
			doctor_id: this.doctorData.id,
			package_id: this.selectedPackage.id,
			dates: reservedDaysIds
		}

		this.globals.showSpinner()
		this.DoctorsApi.reserve(reserveData).subscribe((res: any) => {
			if (res.message)
				this.globals.showToast(res.message, 'error')
			else {
				this.selectedDay = null
				this.selectedDays = []
				this.step = 3
				this.userCredit = this.userCredit - (this.selectedPackage.price ? this.selectedPackage.price : this.doctorData.doctor.session_price * this.selectedPackage.sessions_count)
				this.globals.currentUser.user.credit = this.userCredit

				this.globals.socket.emit('userReservation', { userId: this.doctorData.id, doctor_name: this.doctorData.name, package: this.selectedPackage.name })
			}

			this.globals.hideSpinner()
		},
		(error) => {

			console.log('error',error)
			if(error.error.message === "The given data was invalid."){
				if(this.globals.currantLanguage === 'ar'){
					this.globals.showToast('هذه الجلسة تم حجزها من شخص أخر قبلك مباشرة ', 'error')
				} else {
					this.globals.showToast('This session  has already been taken from some one soon', 'error')
				}
			} else {
				this.globals.showToast(this.translate.instant('somethingWrong'), 'error')
			}



			// this.globals.showToast(this.translate.instant('somethingWrong'), 'error')
			this.globals.hideSpinner()
		})
	}

	freeReserve() {
		const reservedDayId = this.selectedDays[0].data.id

		const reserveData = {
			doctor_id: this.doctorData.id,
			date: reservedDayId
		}

		this.globals.showSpinner()
		this.DoctorsApi.freeReserve(reserveData).subscribe((res: any) => {
			if (res.message)
				this.globals.showToast(res.message, 'error')
			else {
				this.selectedDay = null
				this.selectedDays = []
				this.step = 1
			}

			this.globals.hideSpinner()
		},
		(error) => {
			this.globals.showToast(this.translate.instant('somethingWrong'), 'error')
			this.globals.hideSpinner()
		})
	}

	getMonthDays = () => {
		this.availableDates[this.selectedMonth] = this.availableDates[this.selectedMonth].sort((day, next) => day.day - next.day)
		this.sessions_days = this.removeDuplicates(this.availableDates[this.selectedMonth])
	}

	rating = (rate) => Array(rate ? parseInt(rate) : 0)

	useGiftCard = () => {
		this.globals.showSpinner()
		this.paymentService.useGiftCard(this.giftCard).subscribe(
			() => {
				this.giftCard = ''
				this.globals.hideSpinner()
				this.globals.showToast(this.translate.instant('balance.validGiftCard'), 'success')
			},
			() => {
				this.globals.hideSpinner()
				this.globals.showToast(this.translate.instant('balance.invalidGiftCard'), 'error')
			}
		);
	}

	removeDuplicates(array) {
		if (array) {
			return array.filter((obj, pos, arr) => {
				return arr.map(mapObj => mapObj.day).indexOf(obj.day) === pos;
			})
		}

		return []
	}

	formatNumber = (number) => {
		number = parseFloat(number)
		if (typeof Intl == 'undefined' || !Intl)
			return new this.intl.NumberFormat().format(number)
		else return new Intl.NumberFormat().format(number)
	}

	parseTime = (time) => {
		let hour = time.split(':')[0]
		let minutes = time.split(':')[1]
		let ampm = 'AM'

		if (parseInt(hour) > 12) {
			ampm = 'PM'
			hour = parseInt(hour) - 12
		}

		return `${hour}:${minutes} ${ampm}`
	}
}
