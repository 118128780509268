import { Injectable } from "@angular/core";
import {
	CanActivate,
	Router,
	ActivatedRouteSnapshot,
	RouterStateSnapshot
} from "@angular/router";
import { AuthService } from "./auth.service";
import { Globals } from "./Globals";

@Injectable()
export class AuthGuardUser implements CanActivate {
	constructor(private globals: Globals, private router: Router) { }

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean {
		const url: string = state.url;
		
		return this.checkLogin(url, ActivatedRouteSnapshot);
	}

	checkLogin(url: string, route): boolean {
		if (this.globals.currentUser != null && this.globals.currentUser.user.role.name == 'user') {
			return true;
		}

		this.router.navigate(["/notfound"]);
		return false;
	}
}
