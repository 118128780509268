import { Component, OnInit, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AuthService } from "../../services/auth.service";
import { Globals } from "../../services/Globals";
import { ISubscription } from "rxjs/Subscription";
import { Title } from "@angular/platform-browser";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { ApiService } from "../../services/api.service";
import { TranslateService } from "@ngx-translate/core";
import { Events } from 'ionic-angular';
declare var AOS: any;

@Component({
	selector: "login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
	loginForm:FormGroup;
	checkPassword:boolean = false
	submitted = false;
	constructor(
		private authService: AuthService,
		private globals: Globals,
		private router: Router,
		private titleService: Title,
		private spinnerService: Ng4LoadingSpinnerService,
		private apiService: ApiService,
		private translate: TranslateService,
		private events: Events
	) {
		translate.get('titles.login').subscribe(value => titleService.setTitle(value))
	}
	
	ngOnInit() {
		AOS.init()
		let password = (<HTMLInputElement>document.getElementById('pass'))
		console.log(password.value);
		
		this.loginForm = new FormGroup({
			email: new FormControl(
				"",
				Validators.compose([
					Validators.required,
					Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
				])
			),
			password: new FormControl(
				"",
				Validators.compose([Validators.required, Validators.minLength(8)])
			)
		});
	}
	passwordLength = (value) => {
		console.log(value);
		console.log(value.length);
		if(value.length < 8) {
			this.checkPassword = true
		}else {
			this.checkPassword = false
		}
		
	}
	get f() { return this.loginForm.controls; }
	login = data => {
		this.globals.showSpinner()
		this.authService.login(data.email, data.password).subscribe((res: any) => {
			if (res.type === "error"){
				let body = document.getElementById('main-body')
					if (body.classList.contains('rtl')) {
						this.globals.showToast(res.message == 'unauthorized' ? this.translate.instant('thisAccount') : 'خطأ في الايميل أو الرقم السري', 'error')
					} else {
						this.globals.showToast(res.message == 'unauthorized' ? this.translate.instant('thisAccount') : res.message, 'error')
					}
				
			}
			else {
				if (res.user.is_verified == 1) {
					this.authService.updateCredentials()
					this.globals.secureStorage.setItem("currentUser", JSON.stringify(res))
					this.globals.currency = res.user.currency
					this.globals.currentUser = res
					this.apiService.updateUrls(res.user.role.name)
					this.authService.updateCredentials()

					this.router.navigate(["/"])
					this.events.publish('loggedIn', true)
				}
				else this.router.navigate(["/auth/account-activation/", res.access_token])
			}

			this.globals.hideSpinner()
		},
		() => this.globals.showToast(this.translate.instant('somethingWrong'), 'error'))
	}
}
