import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "./api.service";
import { Globals } from "./Globals";

@Injectable()
export class DoctorsService {
  url = "https://psycounselor.com:49753/api";
  constructor(
    private http: HttpClient,
    private api: ApiService,
    private globals: Globals
  ) {}

  getDoctors = options => this.http.post(this.api.urls.doctors, options);

  getOneDoctor = id => this.http.get(this.api.urls.getOneDoctor + id);

  updateBasicInfo = updates =>
    this.api.onlyFetch(this.api.urls.updateDoctorBasicInfo, updates, 'post', null, true);

  updateProfileInfo = updates =>
    this.api.onlyFetch(this.api.urls.updateDoctorProfileInfo, updates);

  updateDoctorDates = dates =>
    this.api.onlyFetch(this.api.urls.updateDoctorDates, { dates });

  loadMore(options, page) {
    return this.http.post(this.api.urls.doctors, options, {
      params: { page: page.toString() }
    });
  }

  getReservations = (doctorId, packageId) => {
    return this.api.onlyFetch(this.api.urls.getReservations, {
      doctor_id: doctorId,
      package_id: packageId
    });
  };

  getFreeReservations = doctorId => {
    return this.api.onlyFetch(this.api.urls.getFreeReservations, {
      doctor_id: doctorId
    });
  };

  updateUserProfile = updates => {
    return this.http.post(this.api.urls.updateUserProfile, updates, {
      headers: {
        Authorization: `Bearer ${this.globals.currentUser.access_token}`
      }
    });
  };

  // Get specific doctors
  one = (doctorId, userId) => {
    return this.api.anyFetch(
      this.api.urls.getOneDoctor + doctorId,
      { doctor_id: doctorId, ...(userId != null ? { user_id: userId } : {}) },
      "get"
    );
  };

  // Get specific doctor Reviews
  getReviews = id => {
    return this.http.get(this.api.urls.doctorReviews + id);
  };
  // Delete image 

  deleteFile = (id) => {

    // this.api.onlyFetch(this.api.urls.updateDoctorBasicInfo, id, 'delete', null, true);

    return  this.http.delete(this.api.urls.deleteFile + id,{
      headers: {
        Authorization: `Bearer ${this.globals.currentUser.access_token}`
      }
    })
    // .subscribe(res=>{
    //   console.log('res',res);
      
    // });
  }

  getAvailableDates = () =>
    this.api.onlyFetch(this.api.urls.getAvailableDates, {}, "get");

  // Reserve

  reserve = data => this.api.onlyFetch(this.api.urls.reserve, data);
  freeReserve = data => this.api.onlyFetch(this.api.urls.freeReserve, data);
}
